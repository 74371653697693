<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <!-- <b-loading
      v-if="fetchingDashboard"
      :is-full-page="true"
      v-model="fetchingDashboard"
      :can-cancel="false"
    ></b-loading> -->
    <section class="section is-main-section">
      <b-message :active="isPaymentMessageActive" title="Aviso!" type="is-danger" :closable="false" size="is-large">
        <b>
          Por favor, regularize o contrato para evitar indisponibilidade do serviço.
        </b>
      </b-message>
      <tiles>
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="calendar-today"
          :number="getTicketsTodayCount"
          label="Hoje"
        />
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="account-arrow-right-outline"
          :number="getTicketsAttendingCount"
          label="Em atendimento"
        />
        <card-widget
          class="tile is-child"
          type="is-info"
          icon="account-group-outline"
          :number="getOpenTicketsCount"
          label="Abertos"
        />
        <card-widget
          class="tile is-child"
          type="is-primary"
          icon="account-group"
          :number="getAllTicketsCount"
          label="Totais"
        />
        <!-- prefix="$"  suffix="%" -->
        <!-- <card-widget
          class="tile is-child"
          type="is-success"
          icon="chart-timeline-variant"
          :number="256"
          label="Performance"
        /> -->
      </tiles>

      <card-component
        title="Novos atendimentos este ano"
        icon="finance"
        header-icon="reload"
        @header-icon-click="fetchAll"
        v-if="getIsAdmin || this.getIsSupervisor"
      >
        <div v-if="defaultChart.ticketsChartData" class="chart-area">
          <line-chart
            ref="bigChart"
            style="height: 100%;"
            chart-id="big-line-chart"
            :chart-data="defaultChart.ticketsChartData"
            :extra-options="defaultChart.extraOptions"
          >
          </line-chart>
        </div>
      </card-component>

      <card-component
        title="Status dos atendimentos em aberto"
        icon="finance"
        header-icon="reload"
        @header-icon-click="fetchAll"
        v-if="getIsAdmin || this.getIsSupervisor"
      >
        <div v-if="defaultChart.statusChartData" class="chart-area">
          <pie-chart
            ref="bigChart"
            style="height: 100%;"
            chart-id="big-line-chart"
            :chart-data="defaultChart.statusChartData"
            :extra-options="defaultChart.extraOptions"
          >
          </pie-chart>
        </div>
      </card-component>

      <card-component
        title="Divisão de atendimento por departamento (30 dias)"
        icon="finance"
        header-icon="reload"
        @header-icon-click="fetchAll"
        v-if="getIsAdmin || this.getIsSupervisor"
      >
        <div v-if="defaultChart.departmentChart" class="chart-area">
          <pie-chart
            ref="bigChart"
            style="height: 100%;"
            chart-id="big-line-chart"
            :chart-data="defaultChart.departmentChart"
            :extra-options="defaultChart.extraOptions"
          >
          </pie-chart>
        </div>
      </card-component>

      <card-component
        title="Divisão de atendimento por atendente (30 dias)"
        icon="finance"
        header-icon="reload"
        @header-icon-click="fetchAll"
        v-if="getIsAdmin || this.getIsSupervisor"
      >
        <div v-if="defaultChart.attendantChart" class="chart-area">
          <pie-chart
            ref="bigChart"
            style="height: 100%;"
            chart-id="big-line-chart"
            :chart-data="defaultChart.attendantChart"
            :extra-options="defaultChart.extraOptions"
          >
          </pie-chart>
        </div>
      </card-component>

      <!-- <card-component
        title="Tickets abertos"
        class="has-table has-mobile-sort-spaced"
      >
        <b-table
          :data="dashboard.ticketsOpen"
          :bordered="true"
          :striped="true"
          :mobile-cards="true"
          scrollable
          paginated
          per-page="10"
        >
          <b-table-column field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column
            field="client_name"
            label="Nome no whats"
            v-slot="props"
          >
            {{ props.row.client_name }}
          </b-table-column>

          <b-table-column
            field="contact"
            label="Contato na Zeeps"
            v-slot="props"
          >
            {{
              props.row.contact
                ? `${props.row.contact.id} - ${props.row.contact.name}`
                : ''
            }}
          </b-table-column>

          <b-table-column field="client_phone" label="Telefone" v-slot="props">
            {{ props.row.client_phone }}
          </b-table-column>

          <b-table-column
            field="department"
            label="Selecionou dep.?"
            v-slot="props"
          >
            {{
              props.row.department
                ? `${props.row.department.id} - ${props.row.department.name}`
                : 'Sem departamento selecionado'
            }}
          </b-table-column>

          <b-table-column field="client_phone" label="Tipo" v-slot="props">
            <is-group-tag :is_group="props.row.is_group" />
          </b-table-column>

          <b-table-column
            field="attendant_name"
            label="Atendente"
            v-slot="props"
          >
            {{ props.row.attendant ? props.row.attendant.name : '' }}
          </b-table-column>

          <b-table-column
            field="created_at"
            label="Data de abertura"
            v-slot="props"
          >
            {{ formatDateTime(props.row.created_at) }}
          </b-table-column>

          <b-table-column field="actions" label="Detalhes" v-slot="props">
            <button
              class="button"
              expanded
              type="button"
              @click="info('details', props.row)"
            >
              Detalhes
            </button>
          </b-table-column>

          <b-table-column field="actions" label="Fechar" v-slot="props">
            <CloseTicketButton
              :ticket="props.row"
              v-on:reloadTickets="info('reloadTickets')"
            />
          </b-table-column>
        </b-table>
      </card-component> -->
    </section>

    <ModalTicketDetails
      v-if="isModalTicketDetailsOpen"
      :ticket="selectedTicket"
      :showMessagesOption="true"
      v-on:closeModal="closeModal"
      :isComponentModalActive="isModalTicketDetailsOpen"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import * as chartConfig from '@/components/Charts/chart.config'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import LineChart from '@/components/Charts/LineChart'
import PieChart from '@/components/Charts/PieChart'
// import ClientsTableSample from '@/components/ClientsTableSample'
import { mapActions, mapGetters } from 'vuex'
import ModalTicketDetails from '@/components/modals/ModalTicketDetails'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mixin from '../utils/mixins'
// import CloseTicketButton from '@/components/tickets/CloseTicketButton'
// import IsGroupTag from '@/components/commons/IsGroupTag.vue'

export default {
  name: 'HomeScreen',
  components: {
    // ClientsTableSample,
    LineChart,
    PieChart,
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar,
    ModalTicketDetails
    // CloseTicketButton,
    // IsGroupTag
  },
  mixins: [mixin],
  data () {
    return {
      isModalTicketDetailsOpen: false,
      isPaymentMessageActive: false,
      selectedTicket: null,
      defaultChart: {
        ticketsChartData: null,
        statusChartData: null,
        attendantChart: null,
        departmentChart: null,
        extraOptions: chartConfig.chartOptionsMain
      }
    }
  },
  computed: {
    ...mapGetters([
      'dashboard',
      'graphics',
      'fetchingDashboard',
      'getAllTicketsCount',
      'getOpenTicketsCount',
      'getTicketsTodayCount',
      'getTicketsAttendingCount',
      'getTicketsByDepartment',
      'getTicketsByAttendant',
      'getTicketsByStatus',
      'getIsAdmin',
      'getIsSupervisor',
      'allDepartments',
      'allAttendants'
    ]),
    titleStack () {
      return ['Admin', 'Dashboard']
    },
    ticketsByDepartment () {
      if (!this.allDepartments.length === 0) return []

      return this.getTicketsByDepartment.map(tkt => {
        return {
          ...tkt,
          name: this.allDepartments.find(att => att.id === tkt.department_id)?.name ?? 'Sem departamento'
        }
      })
    },
    ticketsByAttendant () {
      if (this.allAttendants.length === 0) return []

      return this.getTicketsByAttendant.map(tkt => {
        return {
          ...tkt,
          name: this.allAttendants.find(att => att.id === tkt.attendant_id)?.name ?? 'Sem atendente'
        }
      })
    },
    ticketsByStatus () {
      if (!this.allDepartments.length === 0) return []

      return this.getTicketsByStatus.map(tkt => {
        return {
          ...tkt,
          name: this.allDepartments.find(att => att.id === tkt.department_id)?.name ?? 'Sem departamento'
        }
      })
    }
  },
  async mounted () {
    this.fetchAll()
    this.isPaymentMessageActive = process.env.VUE_APP_PAYMENT_MESSAGE_ACTIVE === 'true'
  },
  methods: {
    ...mapActions([
      'fetchDashboard',
      'fetchGraphics',
      'fetchTicketsByDepartment',
      'fetchTicketsByAttendant',
      'fetchTicketsByStatus',
      'fetchDepartments',
      'fetchAttendants'
    ]),
    async fetchAll () {
      this.fetchDashboard()
      if (this.getIsAdmin || this.getIsSupervisor) {
        this.fetchDepartments()
        this.fetchAttendants()
        await this.fetchTicketsByDepartment()
        await this.fetchTicketsByAttendant()
        await this.fetchTicketsByStatus()
        await this.fetchGraphics()
        this.fillChartData()
      }
    },
    info (action, ticket) {
      switch (action) {
        case 'details':
          this.selectedTicket = ticket
          this.isModalTicketDetailsOpen = true
          break
        case 'reloadTickets':
          this.fetchAll()
          break
        default:
          break
      }
    },
    closeModal () {
      this.isModalTicketDetailsOpen = false
    },
    randomChartData (n) {
      const data = []

      for (let i = 0; i < n; i++) {
        data.push(Math.round(Math.random() * 200))
      }

      return data
    },
    fillChartData () {
      this.defaultChart.ticketsChartData = {
        datasets: [
          {
            fill: false,
            borderColor: chartConfig.chartColors.default.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: chartConfig.chartColors.default.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.graphics
              ? this.graphics.map(data => data.tickets_qty)
              : []
          }
        ],
        labels: [
          'Jan',
          'Fev',
          'Mar',
          'Abr',
          'Mai',
          'Jun',
          'Jul',
          'Ago',
          'Set',
          'Out',
          'Nov',
          'Dez'
        ]
      }

      const ticketsStatus = {}
      if (this.ticketsByStatus) {
        this.ticketsByStatus.map(tkt => {
          ticketsStatus[tkt.name] = tkt.count
        })

        this.defaultChart.statusChartData = {
          datasets: [
            {
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue'
              ],
              hoverOffset: 4,
              data: [...Object.values(ticketsStatus)]
            }
          ],
          labels: [...Object.keys(ticketsStatus)]
        }
      }

      const ticketsAttendants = {}
      if (this.ticketsByAttendant) {
        this.ticketsByAttendant.map(tkt => {
          ticketsAttendants[tkt.name] = tkt.count
        })

        this.defaultChart.attendantChart = {
          datasets: [
            {
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue'
              ],
              hoverOffset: 4,
              data: [...Object.values(ticketsAttendants)]
            }
          ],
          labels: [...Object.keys(ticketsAttendants)]
        }
      }

      const ticketsDepartment = {}
      if (this.ticketsByDepartment) {
        this.ticketsByDepartment.map(tkt => {
          ticketsDepartment[tkt.name] = tkt.count
        })

        this.defaultChart.departmentChart = {
          datasets: [
            {
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue',
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'green',
                'blue'
              ],
              hoverOffset: 4,
              data: [...Object.values(ticketsDepartment)]
            }
          ],
          labels: [...Object.keys(ticketsDepartment)]
        }
      }
    }
  }
}
</script>
