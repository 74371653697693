/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// reasons module
import { get, post, put, del } from '../../utils/api'

const state = {
  fetchingReasons: false,
  reasons: []
}

const getters = {
  allReasons: state => {
    return state.reasons.map(reason => {
      const res = reason
      if (res.contact) {
        res.contactTo = `(${res.contact.id} - ${res.contact.name}) ${res.to}`
      } else {
        res.contactTo = res.to
      }

      switch (res.redirect_type) {
        case 'NRE':
          res.redirectTypeTitle = 'Seguir o fluxo normal de atendimento'
          break
        case 'REA':
          res.redirectTypeTitle = 'Redirecionar para o último atendente selecionado'
          break
        case 'RED':
          res.redirectTypeTitle = 'Redirecionar para o último departamento selecionado'
          break
        default:
          res.redirectTypeTitle = 'Opção não encontrada'
          break
      }

      switch (res.reason_type) {
        case 'FIN':
          res.reasonTypeTile = 'Atendimento finalizado com sucesso'
          break
        case 'FIE':
          res.reasonTypeTile = 'Atendimento finalizado com problemas'
          break
        case 'PEN':
          res.reasonTypeTile = 'Atendimento pendente de reavaliação futura'
          break
        case 'NUR':
          res.reasonTypeTile = 'Atendimento sem resposta do cliente'
          break
        default:
          res.reasonTypeTile = 'Opção não encontrada'
          break
      }

      return res
    })
  },
  getReasonById: (state) => (id) => {
    return state.reasons.find(reason => reason.id === id)
  },
  fetchingReasons: state => state.fetchingReasons
}

const actions = {
  async fetchReasons ({ commit }) {
    commit('setFetchingReasons', true)
    const response = await get('reasons')
    commit('setReasons', response.data)
    commit('setFetchingReasons', false)
    return response
  },
  async fetchEnabledReasons ({ commit }, ww_phone) {
    commit('setFetchingReasons', true)
    const response = await get('reasons/enabledOnly', { ww_phone })
    commit('setReasons', response.data)
    commit('setFetchingReasons', false)
    return response
  },
  async addReason ({ commit, dispatch }, reason) {
    commit('setFetchingReasons', true)
    await post('reasons', reason)
    dispatch('fetchReasons')
  },
  async updateReason ({ commit, dispatch }, reason) {
    commit('setFetchingReasons', true)
    await put(`reasons/${reason.id}`, reason)
    dispatch('fetchReasons')
  },
  async removeReason ({ dispatch }, id) {
    await del(`reasons/${id}`)
    await dispatch('fetchReasons')
  }
}

const mutations = {
  setReasons: (state, reasons) => state.reasons = reasons,
  setFetchingReasons: (state, fetching) => state.fetchingReasons = fetching,
  updReason: (state, updatedReason) => {
    const index = state.reasons.findIndex(t => t.id === updatedReason.id)
    if (index !== -1) {
      state.reasons.splice(index, 1, updatedReason)
    }
  },
  deleteReason: (state, reason) => state.reasons = state.reasons.filter(p => reason.id !== p.id)
}

export default {
  state, getters, actions, mutations
}
