// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingDepartmentsReports: false,
  report: [],
  tickets: [],
  attendants: []
}

const getters = {
  getDepartmentsReports: state => {
    return state.report
  },
  getDepartmentsReportsTickets: state => {
    return state.tickets
  },
  getDepartemntsReportsAttendants: state => {
    return state.attendants
  },
  fetchingDepartmentsReports: state => {
    return state.fetchingDepartmentsReports
  }
}

const actions = {
  async fetchDepartmentsReports ({ commit }, data) {
    commit('setFetchingDepartmentsReports', true)
    const response = await get('reports/departments', data)
    commit('setDepartmentsReports', response.data)
    commit('setFetchingDepartmentsReports', false)
  },
  async fetchDepartmentsReportsTickets ({ commit }, data) {
    commit('setFetchingDepartmentsReports', true)
    const response = await get('reports/departments/tickets', data)
    commit('setDepartmentsReportsTickets', response.data)
    commit('setFetchingDepartmentsReports', false)
  },
  async fetchDepartmentsReportsAttendants ({ commit }, data) {
    commit('setFetchingDepartmentsReports', true)
    const response = await get('reports/departments/attendants', data)
    console.log(response.data)
    commit('setDepartmentsReportsAttendants', response.data)
    commit('setFetchingDepartmentsReports', false)
  },
  async clearDepartmentsReportsTicketsData ({ commit }) {
    commit('setDepartmentsReportsTickets', [])
  }
}

const mutations = {
  setDepartmentsReports: (state, report) => state.report = report,
  setDepartmentsReportsAttendants: (state, attendants) => state.attendants = attendants,
  setDepartmentsReportsTickets: (state, tickets) => state.tickets = tickets,
  setFetchingDepartmentsReports: (state, fetchingDepartmentsReports) => state.fetchingDepartmentsReports = fetchingDepartmentsReports
}

export default {
  state, getters, actions, mutations
}
