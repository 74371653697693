/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// settings module

import { get, post, put } from '../../utils/api'

const state = {
  fetchingQrCode: false,
  tokens: [],
  tokensEnabled: [],
  qrCode: undefined
}

const getters = {
  allTokens: state => {
    return state.tokens
  },
  allTokensEnabled: state => {
    return state.tokensEnabled
  },
  getQRCode: state => state.qrCode,
  fetchingQrCode: state => state.fetchingQrCode
}

const actions = {
  async fetchTokens ({ commit }) {
    commit('setFetchingQrCode', true)
    const response = await get('tokens')
    commit('setTokens', response.data)
    commit('setTokensEnabled', response.data)
    commit('setFetchingQrCode', false)
  },
  async fetchQRCode ({ commit }, phone) {
    commit('setFetchingQrCode', true)
    try {
      await post(`tokens/${phone}`)
    }
    finally {
      commit('setFetchingQrCode', false)
    }
  },
  async changeQRCodeLocal ({ commit }, qr) {
    commit('setFetchingQrCode', false)
    commit('setQRCode', qr)
  },
  async configSettings ({ dispatch }, setting) {
    await post('settings', setting)
    dispatch('fetchSettings')
  },
  async resetTokens ({ commit }) {
    commit('setFetchingQrCode', true)
    await post('tokens/resetTokens')
    commit('setFetchingQrCode', false)
  },
  async addPhone ({ dispatch, commit }, phone) {
    try {
      commit('setFetchingQrCode', true)
      await post('phones', phone)
      dispatch('fetchTokens')
    } finally {
      commit('setFetchingQrCode', false)
    }
  },
  async updatePhone ({ dispatch, commit }, phone) {
    try {
      commit('setFetchingQrCode', true)
      await put(`phones/${phone.phone}`, phone)
      dispatch('fetchTokens')
    } finally {
      commit('setFetchingQrCode', false)
    }
  }
}

const mutations = {
  setTokens: (state, tokens) => state.tokens = tokens,
  setQRCode: (state, qrCode) => state.qrCode = qrCode,
  setFetchingQrCode: (state, fetching) => state.fetchingQrCode = fetching,
  setTokensEnabled: (state, tokens) => state.tokensEnabled = tokens.filter(s => s.enabled)
}

export default {
  state, getters, actions, mutations
}
