// departments module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingDepartments: false,
  departments: [],
  sentQuestions: []
}

const getters = {
  allDepartments: state => {
    return state.departments
  },
  allDepartmentsEnabled: state => {
    return state.departments.filter(dpt => dpt.enabled)
  },
  fetchingDepartments: state => state.fetchingDepartments,
  departmentSentQuestions: state => {
    return state.sentQuestions.map(s => s.id)
  }
}

const actions = {
  async fetchDepartments ({ commit }, ww_phone) {
    try {
      commit('setFetchingDepartments', true)
      const response = await get('departments', { ww_phone })
      commit('setDepartments', response.data)
    } finally {
      commit('setFetchingDepartments', false)
    }
  },
  async addDepartment ({ dispatch }, department) {
    await post('departments', department)
    dispatch('fetchDepartments')
  },
  async deleteDepartment ({ dispatch }, department_id) {
    await del(`departments/${department_id}`)
    await dispatch('fetchDepartments')
  },
  async updateDepartment ({ dispatch, commit }, department) {
    try {
      commit('setFetchingDepartments', true)
      await put(`departments/${department.id}`, department)
      dispatch('fetchDepartments')
    } finally {
      commit('setFetchingDepartments', false)
    }
  },
  async fetchSentQuestions ({ commit }, department_id) {
    try {
      const response = await get(`departments/sentQuestions/${department_id}`)
      commit('setSentQuestions', response.data)
    } finally {
      //
    }
  }
}

const mutations = {
  setDepartments: (state, departments) => state.departments = departments,
  setFetchingDepartments: (state, fetching) => state.fetchingDepartments = fetching,
  setSentQuestions: (state, sentQuestions) => state.sentQuestions = sentQuestions
}

export default {
  state, getters, actions, mutations
}
