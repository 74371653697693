// attendants module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, put } from '../../utils/api'

const state = {
  fetchingNotifications: false,
  dataNotifications: {
    notifications: [],
    total: 0,
    page: 0,
    per_page: 0,
    nextPage: 0,
    prevPage: 0
  },
  unreadNotifications: 0,
  fetchingUnreadNotifications: false
}

const getters = {
  fetchingNotifications: state => state.fetchingNotifications,
  getUnreadNotifications: state => state.unreadNotifications,
  fetchingUnreadNotifications: state => state.fetchingUnreadNotifications
}

const actions = {
  async fetchNotifications ({ commit }, filter) {
    try {
      commit('setFetchingNotifications', true)
      const response = await get(`notifications?page=${filter.page}&per_page=${filter.per_page}`)
      commit('setDataNotifications', {
        notifications: response.data.notifications,
        total: response.data.total,
        page: response.data.page,
        per_page: response.data.per_page,
        nextPage: response.data.nextPage,
        prevPage: response.data.prevPage
      })
    } finally {
      commit('setFetchingNotifications', false)
    }
  },
  async fetchUnreadNotifications ({ commit }) {
    try {
      commit('setFetchingUnreadNotifications', true)
      const response = await get('notifications/unread')
      commit('setUnreadNotifications', response.data)
    } finally {
      commit('setFetchingUnreadNotifications', false)
    }
  },
  async markNotificationAsRead ({ commit }, notification) {
    await put(`notifications/${notification.id}/markAsRead`)
  }
}

const mutations = {
  setFetchingNotifications: (state, fetching) => state.fetchingNotifications = fetching,
  setDataNotifications: (state, notifications) => state.dataNotifications = notifications,
  setUnreadNotifications: (state, unReadNotifications) => state.unreadNotifications = unReadNotifications,
  setFetchingUnreadNotifications: (state, fetch) => state.fetchingUnreadNotifications = fetch
}

export default {
  state, getters, actions, mutations
}
