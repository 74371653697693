// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post } from '../../utils/api'

const state = {
  fetchingWppGroups: false,
  wppGroups: []
}

const getters = {
  allWppGroups: state => {
    return state.wppGroups
  },
  fetchingWppGroups: state => state.fetchingWppGroups
}

const actions = {
  async fetchWppGroups ({ commit }) {
    try {
      commit('setFetchingWppGroups', true)
      const response = await get('tickets/wppGroups')
      commit('setWppGroups', response.data)
    } finally {
      commit('setFetchingWppGroups', false)
    }
  },
  async fetchWppGroupDetails ({ commit }, ticket_id) {
    const response = await get(`tickets/wppGroups/${ticket_id}`)
    return response.data
  },
  async updateWppGroup ({ dispatch, commit }, group) {
    try {
      commit('setFetchingWppGroups', true)
      await post(`tickets/wppGroups/${group.id}`, group)
      dispatch('fetchWppGroups')
    } finally {
      commit('setFetchingWppGroups', false)
    }
  }
}

const mutations = {
  setWppGroups: (state, wppGroups) => state.wppGroups = wppGroups,
  setFetchingWppGroups: (state, fetching) => state.fetchingWppGroups = fetching
}

export default {
  state, getters, actions, mutations
}
