// attendants module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingAttendants: false,
  attendants: []
}

const getters = {
  allAttendants: state => {
    return state.attendants
  },
  allAttendantsEnabled: state => {
    return state.attendants.filter(att => att.isActive)
  },
  fetchingAttendants: state => state.fetchingAttendants
}

const actions = {
  async fetchAttendants ({ commit }) {
    try {
      commit('setFetchingAttendants', true)
      const response = await get('attendants')
      commit('setAttendants', response.data)
    } finally {
      commit('setFetchingAttendants', false)
    }
  },
  async addAttendant ({ dispatch, commit }, attendant) {
    try {
      commit('setFetchingAttendants', true)
      await post('attendants', attendant)
      dispatch('fetchAttendants')
    } finally {
      commit('setFetchingAttendants', false)
    }
  },
  async updateAttendant ({ dispatch, commit }, attendant) {
    try {
      commit('setFetchingAttendants', true)
      await put(`attendants/${attendant.id}`, attendant)
      dispatch('fetchAttendants')
    } finally {
      commit('setFetchingAttendants', false)
    }
  },
  async deleteAttendant ({ dispatch }, attendant_id) {
    await del(`attendants/${attendant_id}`)
    await dispatch('fetchAttendants')
  },
  async addContactToAttendant ({ dispatch, commit }, data) {
    const request = await post('attendants/addContactToAttendant', data)
    return request.data
  },
  async fetchCurrentAttendantDepartmentsAndReturn ({ dispatch, commit }) {
    const request = await post('attendants/getCurrentAttendantDepartments')
    return request.data
  },
}

const mutations = {
  setAttendants: (state, attendants) => state.attendants = attendants,
  setFetchingAttendants: (state, fetching) => state.fetchingAttendants = fetching
}

export default {
  state, getters, actions, mutations
}
