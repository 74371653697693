import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import store from '../store/index'
import { post, get } from '../utils/api'

const validateToken = async () => {
  return await post('sessions/validate')
}

const getPermissions = async () => {
  const response = await get(`attendants/permissions/${store.getters.getUserId}`)
  return response.data
}

Vue.use(VueRouter)

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      title: 'Dashboard de Atendentes',
      requiresAuth: true,
      adminOrSupervisorsOnly: true
    },
    path: '/attendantsDashboard',
    name: 'attendantsDashboard',
    component: () => import('../views/AttendantsDashboard.vue')
  },
  {
    meta: {
      title: 'Chat',
      requiresAuth: true,
      notAdmin: true,
      notOffline: true,
      hideASideMenu: true
    },
    path: '/chat',
    name: 'chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tables" */ '../views/Chat.vue')
  },
  {
    meta: {
      title: 'Atendentes',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/atendentes',
    name: 'attendants',
    component: () => import(/* webpackChunkName: "attendants" */ '../views/Attendants.vue')
  },
  {
    meta: {
      title: 'Reports',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/reports-menu',
    name: 'reports-menu',
    component: () => import(/* webpackChunkName: "reports" */ '../views/MenuReports.vue')
  },
  {
    meta: {
      title: 'Reports',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/departments-reports',
    name: 'departments-reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/DepartmentsReports.vue')
  },
  {
    meta: {
      title: 'Reports',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/attendants-reports',
    name: 'attendants-reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/AttendantsReports.vue')
  },
  {
    meta: {
      title: 'Reports',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/contacts-reports',
    name: 'contacts-reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/ContactsReports.vue')
  },
  {
    meta: {
      title: 'Reports',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/surveys-reports',
    name: 'surveys-reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/SatisfactionSurveysReports.vue')
  },
  {
    meta: {
      title: 'Mensagens agendadas',
      requiresAuth: true,
      canSchedule: true
    },
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue')
  },
  {
    meta: {
      title: 'Notificações',
      requiresAuth: true
    },
    path: '/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue')
  },
  {
    meta: {
      title: 'Mensagens predefinidas',
      requiresAuth: true,
      adminOrSupervisorsOnly: true
    },
    path: '/default-messages',
    name: 'default-messages',
    component: () => import(/* webpackChunkName: "default-messages" */ '../views/DefaultMessage.vue')
  },
  {
    meta: {
      title: 'Departamentos',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/departamentos',
    name: 'departments',
    component: () => import(/* webpackChunkName: "departments" */ '../views/Departments.vue')
  },
  {
    meta: {
      title: 'Opções',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/opcoes',
    name: 'options',
    component: () => import(/* webpackChunkName: "departments" */ '../views/MenuOptions.vue')
  },
  {
    meta: {
      title: 'Usuários',
      requiresAuth: true,
      adminOnly: true
    },
    path: '/usuarios',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    meta: {
      title: 'Relatório de tickets',
      requiresAuth: true,
      adminOnly: false
    },
    path: '/ticketsReport',
    name: 'ticketsReport',
    component: () => import(/* webpackChunkName: "ticketsReport" */ '../views/TicketsReport.vue')
  },
  {
    meta: {
      title: 'Gerenciamento de tickets',
      requiresAuth: true,
      adminOnly: false,
      supervisorsOnly: true
    },
    path: '/ticketsManagement',
    name: 'ticketsManagement',
    component: () => import(/* webpackChunkName: "ticketsManagement" */ '../views/TicketsManagement.vue')
  },
  {
    meta: {
      title: 'Contatos',
      requiresAuth: true,
      adminOnly: false
    },
    path: '/contatos',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
  },
  {
    meta: {
      title: 'Grupos de contatos',
      requiresAuth: true,
      adminOnly: false
    },
    path: '/grupos',
    name: 'grupos',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/ContactsGroups.vue')
  },
  {
    meta: {
      title: 'Tags de contatos',
      requiresAuth: true,
      adminOnly: false
    },
    path: '/tags',
    name: 'tags',
    component: () => import(/* webpackChunkName: "tags" */ '../views/Tags.vue')
  },
  {
    meta: {
      title: 'Razoes',
      requiresAuth: true,
      userWithoutKey: true,
      adminOrSupervisorOnly: true
    },
    path: '/razoes',
    name: 'razoes',
    component: () => import(/* webpackChunkName: "razoes" */ '../views/TicketsCloseReason.vue')
  },
  // {
  //   meta: {
  //     title: 'Configurações',
  //     requiresAuth: true,
  //     adminOrSupervisorOnly: true
  //   },
  //   path: '/configuracoes',
  //   name: 'configurations',
  //   component: () => import(/* webpackChunkName: "configurations" */ '../views/Configurations.vue')
  // },
  {
    meta: {
      title: 'Configurações',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/configuracoes/:phone',
    name: 'configurations',
    component: () => import(/* webpackChunkName: "configurations" */ '../views/Configurations.vue')
  },
  {
    meta: {
      title: 'Integrações',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/integracoes',
    name: 'integrations',
    component: () => import(/* webpackChunkName: "configurations" */ '../views/ExternalSystems.vue')
  },
  {
    meta: {
      title: 'Permissões',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/permissions',
    name: 'permissions',
    component: () => import(/* webpackChunkName: "configurations" */ '../views/Permissions.vue')
  },
  {
    meta: {
      title: 'Detalhes da integração',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/integracoes/:id',
    name: 'detalhes-integracoes',
    component: () => import(/* webpackChunkName: "configurations" */ '../views/ExternalSystemsDetails.vue')
  },
  {
    meta: {
      title: 'Mapear QRCode',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/qrcode',
    name: 'qrcode',
    component: () => import(/* webpackChunkName: "qrcode" */ '../views/QRCode.vue')
  },
  {
    meta: {
      title: 'Não autorizado',
      isNotAutorizedScreen: true
    },
    path: '/notAutorized',
    name: 'NotAutorized',
    component: () => import(/* webpackChunkName: "notAutorized" */ '../views/NotAutorized.vue')
  },
  {
    meta: {
      title: 'Usuário Offline',
      isUserOfflineScreen: true,
      hideASideMenu: false
    },
    path: '/userOffline',
    name: 'UserOffline',
    component: () => import(/* webpackChunkName: "userOffline" */ '../views/UserOffline.vue')
  },
  {
    meta: {
      title: 'Login',
      requiresNotAuth: true
    },
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    meta: {
      title: 'Login',
      requiresNotAuth: true
    },
    path: '/login/:token',
    name: 'loginToken',
    component: Login
  },
  {
    meta: {
      title: 'Profile',
      requiresAuth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    meta: {
      title: 'Grupos de WhatsApp',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/grupos-wpp',
    name: 'grupos-wpp',
    component: () => import(/* webpackChunkName: "grupos wpp" */ '../views/WppGroups.vue')
  },
  {
    meta: {
      title: 'Templates',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/templates',
    name: 'templates',
    component: () => import(/* webpackChunkName: "templates" */ '../views/Templates.vue')
  },
  {
    meta: {
      title: 'Horários de Funcionamento',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/business-hours',
    name: 'business-hours',
    component: () => import(/* webpackChunkName: "business hours" */ '../views/BusinessHours.vue')
  },
  {
    meta: {
      title: 'Pesquisas de Satisfação NPS',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/satisfaction-surveys',
    name: 'satisfaction-surveys',
    component: () => import(/* webpackChunkName: "satisfaction surveys" */ '../views/SatisfactionSurveys.vue')
  },
  {
    meta: {
      title: 'Configurações Gerais',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/configuracoes-gerais',
    name: 'general-configurarions',
    component: () => import(/* webpackChunkName: "satisfaction surveys" */ '../views/GeneralConfigurations.vue')
  },
  {
    meta: {
      title: 'Horários de Funcionamento',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/business-hours',
    name: 'business-hours',
    component: () => import(/* webpackChunkName: "business hours" */ '../views/BusinessHours.vue')
  },
  {
    meta: {
      title: 'Pesquisas de Satisfação NPS',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/satisfaction-surveys',
    name: 'satisfaction-surveys',
    component: () => import(/* webpackChunkName: "satisfaction surveys" */ '../views/SatisfactionSurveys.vue')
  },
  {
    meta: {
      title: 'Configurações Gerais',
      requiresAuth: true,
      adminOrSupervisorOnly: true
    },
    path: '/configuracoes-gerais',
    name: 'general-configurarions',
    component: () => import(/* webpackChunkName: "satisfaction surveys" */ '../views/GeneralConfigurations.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('fetchAppearance')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAutenticated) {
      try {
        await validateToken()
      } catch (error) {
        store.dispatch('deleteToken')
        next('/login')
        return
      }

      let permissions = {}
      if (store.getters.getIsAdmin) {
        permissions.schedule_messages = true
      } else {
        permissions = await getPermissions()
      }

      if (to.matched.some(record => record.meta.adminOnly) && !store.getters.getIsAdmin) {
        next('/notAutorized')
        return
      } else if (to.matched.some(record => record.meta.notAdmin) && store.getters.getIsAdmin) {
        next('/notAutorized')
        return
      } else if (to.matched.some(record => record.meta.supervisorsOnly) && (!store.getters.getIsAdmin && !store.getters.getIsSupervisor)) {
        next('/notAutorized')
        return
      } else if (to.matched.some(record => record.meta.adminOrSupervisorOnly) && (!store.getters.getIsAdmin && !store.getters.getIsSupervisorAllDepartments)) {
        next('/notAutorized')
        return
      } else if (to.matched.some(record => record.meta.adminOrSupervisorsOnly) && (!store.getters.getIsAdmin && !store.getters.getIsSupervisorAllDepartments && !store.getters.getIsSupervisor)) {
        next('/notAutorized')
        return
      } else if (to.matched.some(record => record.meta.notOffline) && store.getters.getStatus.key === 'offline') {
        next('/userOffline')
        return
      } else if (to.matched.some(record => record.meta.canSchedule) && !store.getters.getIsAdmin && !store.getters.getIsSupervisorAllDepartments && !permissions?.schedule_messages) {
        next('/notAutorized')
        return
      }
      store.dispatch('fetchWarnings')
      if (to.matched.some(record => record.meta.hideASideMenu)) {
        store.dispatch('changeAsideMenuVisibility', false)
      } else {
        store.dispatch('changeAsideMenuVisibility', true)
      }

      store.dispatch('fetchUnreadNotifications')
      next()
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.meta.requiresNotAuth)) {
    if (!store.getters.isAutenticated) {
      next()
      return
    }
    next('/')
  } else {
    store.dispatch('fetchUnreadNotifications')
    next()
  }

  if (to.path === '/userOffline') {
    store.dispatch('changeAsideMenuVisibility', true)
  }
})

export default router
