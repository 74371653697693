<template>

  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="box">
              <h2 class="title">{{ getAppName }}</h2>
              <div class="field">
                <label for="" class="label">Usuário</label>
                <div class="control has-icons-left">
                  <b-input v-model="user"></b-input>
                  <span class="icon is-small is-left">
                    <i class="fa fa-envelope"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Senha</label>
                <div class="control has-icons-left">
                  <b-input type="password" v-model="password" password-reveal @keypress.native.enter="login">
                  </b-input>
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <!-- <div class="field">
                <label for="" class="checkbox">
                <input type="checkbox">
                Remember me
                </label>
              </div> -->
              <div class="field">
                <button class="button is-success" @click="login">
                  Login
                </button>
              </div>
            </div>

            <div class="level-center">
              <div class="level-item">
                <div class="footer-copyright has-text-centered">
                  {{ getAppName }}
                  <br>
                  <span class="tag">Web {{ getVersion }}</span>
                  <br>
                  <span v-if="getApiVersion" class="tag">Server {{ getApiVersion }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginScreen',
  data () {
    return {
      user: '',
      password: ''
    }
  },
  async created () {
    this.fetchAppearance()
    if (this.$route.params.token) {
      await this.fetchUserDetails(this.$route.params.token)
      this.$router.push({ path: '/' })
    }
  },
  computed: {
    ...mapGetters(['getAppName', 'getVersion', 'getApiVersion'])
  },
  methods: {
    ...mapActions(['fetchDoLogin', 'fetchUserDetails', 'fetchAppearance']),
    login () {
      this.fetchDoLogin({ username: this.user, password: this.password }).then(() => {
        this.$router.push({ path: '/' })
      })
        .catch(() => {
          this.$buefy.dialog.alert({
            title: 'Erro de login',
            message: '<b>Usuário</b> ou <b>senha</b> incorretos, por favor confira os dados e tente novamente!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  padding: 2%;
}
</style>
