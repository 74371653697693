<template>
  <div
    class="navbar-item has-dropdown has-dropdown-with-icons"
    :class="{ 'is-hoverable': isHoverable, 'is-active': isDropdownActive }"
  >
    <a class="navbar-link is-arrowless" @click="toggleDropdown">
      <slot />
      <b-icon :icon="toggleDropdownIcon" custom-size="default" />
    </a>
    <slot name="dropdown" />
  </div>
</template>

<script>
export default {
  name: 'NavBarMenu',
  props: {
    isHoverable: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      dafault: false
    }
  },
  data () {
    return {
      isDropdownActive: false
    }
  },
  watch: {
    isActive (newValue) {
      this.isDropdownActive = newValue
    }
  },
  created () {
    this.isDropdownActive = this.isActive
  },
  computed: {
    toggleDropdownIcon () {
      return this.isDropdownActive ? 'chevron-up' : 'chevron-down'
    }
  },
  mounted () {
    window.addEventListener('click', this.forceClose)
  },
  beforeDestroy () {
    window.removeEventListener('click', this.forceClose)
  },
  methods: {
    toggleDropdown () {
      this.$emit('toggleDropdown')
    },
    forceClose (e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdownActive = false
      }
    }
  }
}
</script>
