// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, put, del } from '../../utils/api'

const state = {
  fetchingDefaultMessages: false,
  defaultMessages: []
}

const getters = {
  allDefaultMessages: state => {
    return state.defaultMessages
  },
  fetchingDefaultMessages: state => state.fetchingDefaultMessages
}

const actions = {
  async fetchDefaultMessages ({ commit }) {
    try {
      commit('setFetchingDefaultMessages', true)
      const response = await get('default-messages')
      commit('setDefaultMessages', response.data)
    } finally {
      commit('setFetchingDefaultMessages', false)
    }
  },
  async fetchDefaultMessagesWithoutFile ({ commit }) {
    try {
      commit('setFetchingDefaultMessages', true)
      const response = await get('default-messages/without_file')
      commit('setDefaultMessages', response.data)
    } finally {
      commit('setFetchingDefaultMessages', false)
    }
  },
  async fetchPredefinedMessageDetail ({ commit }, id) {
    return await get(`default-messages/details/${id}`)
  },
  async addDefaultMessage ({ dispatch }, defaultMessage) {
    await post('default-messages', defaultMessage)
    dispatch('fetchDefaultMessages')
  },
  async updateDefaultMessage ({ dispatch, commit }, defaultMessage) {
    try {
      commit('setFetchingDefaultMessages', true)
      await put(`default-messages/${defaultMessage.id}`, defaultMessage)
      dispatch('fetchDefaultMessages')
    } finally {
      commit('setFetchingDefaultMessages', false)
    }
  },
  async deleteDefaultMessages ({ dispatch }, defaultMessage_id) {
    await del(`default-messages/${defaultMessage_id}`)
    await dispatch('fetchDefaultMessages')
  }
}

const mutations = {
  setDefaultMessages: (state, defaultMessages) => state.defaultMessages = defaultMessages,
  setFetchingDefaultMessages: (state, fetching) => state.fetchingDefaultMessages = fetching
}

export default {
  state, getters, actions, mutations
}
