<template>
  <footer v-show="isFooterBarVisible && isAutenticated && $route.name !== 'chat'" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; {{ year }}</b> &mdash; {{ getAppName }}
              <span class="tag">v{{ getVersion }} | v{{ getApiVersion }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapGetters } from 'vuex'
import { apiUri } from '../utils/api'

export default {
  name: 'FooterBar',
  computed: {
    year () {
      return dayjs().year()
    },
    ...mapGetters(['isAutenticated', 'getAppName', 'getVersion', 'getApiVersion']),
    ...mapState({
      isFooterBarVisible: state => state.theme.isFooterBarVisible
    })
  },
  methods: {
    getLogoUri () {
      console.log(`${apiUri}/appearance/logo`)
      return `${apiUri}/appearance/logo`
    }
  }
}
</script>
