/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  warnings: {},
  appName: ''
}

const getters = {
  getWarnings: state => state.warnings,
  getAppName: state => state.appName
}

const actions = {
  async fetchWarnings ({ commit }) {
    const response = await get('warnings')
    commit('setWarnings', response.data)
  },

  async fetchAppearance ({ commit }) {
    const response = await get('appearance')
    const appearance = response.data
    console.log(appearance)
    if (appearance) {
      commit('setAppName', appearance.app_name)

      document.documentElement.style.setProperty(
        '--aside-text-color-active',
        appearance.aside_text_color_active
      )
      document.documentElement.style.setProperty(
        '--aside-background-color',
        appearance.aside_background_color
      )
      document.documentElement.style.setProperty(
        '--aside-tools-background-color',
        appearance.aside_logo_background_color
      )
      document.documentElement.style.setProperty(
        '--aside-text-color',
        appearance.aside_text_color
      )
    } else {
      commit('setAppName', 'Zeeps')
    }
  }
}

const mutations = {
  setWarnings: (state, warnings) => state.warnings = warnings,
  setAppName: (state, appName) => state.appName = appName
}

export default {
  state, getters, actions, mutations
}
