// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del } from '../../utils/api'

const state = {
  fetchingContactsFieldsQuestions: false,
  contactsFieldsQuestions: []
}

const getters = {
  allContactsFieldsQuestions: state => {
    return state.contactsFieldsQuestions
  },
  fetchingContactsFieldsQuestions: state => state.fetchingContactsFieldsQuestions
}

const actions = {
  async fetchContactsFieldsQuestions ({ commit }) {
    try {
      commit('setFetchingContacts', true)
      const response = await get('contacts-fields-questions')
      commit('setContactsFieldsQuestions', response.data)
    } finally {
      commit('setFetchingContacts', false)
    }
  },
  async addContactFieldQuestion ({ dispatch }, contact) {
    const newContact = await post('contacts-fields-questions', contact)
    dispatch('fetchContactsFieldsQuestions')
    return newContact
  },
  async deleteContactFieldQuestion ({ dispatch }, contact) {
    await del(`contacts-fields-questions/${contact.id}/${contact.transferTo}`)
    await dispatch('fetchContactsFieldsQuestions')
  }
}

const mutations = {
  setContactsFieldsQuestions: (state, contactsFieldsQuestions) => state.contactsFieldsQuestions = contactsFieldsQuestions,
  setFetchingContactsFieldsQuestions: (state, fetching) => state.fetchingContactsFieldsQuestions = fetching

}

export default {
  state, getters, actions, mutations
}
