// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingContactsReport: false,
  report: {},
  tickets: []
}

const getters = {
  getContactsReports: state => {
    return state.report
  },
  getContactsReportsTickets: state => {
    return state.tickets
  },
  fetchingContactsReports: state => {
    return state.fetchingContactsReport
  }
}

const actions = {
  async fetchContactsReports ({ commit }, data) {
    commit('setFetchingContactsReport', true)
    const response = await get('reports/contacts', data)
    commit('setContactsReport', response.data)
    commit('setFetchingContactsReport', false)
  },
  async fetchContactsReportsTickets ({ commit }, data) {
    commit('setFetchingContactsReport', true)
    const response = await get('reports/contacts/tickets', data)
    commit('setContactsReportTickets', response.data)
    commit('setFetchingContactsReport', false)
  },
  async clearContactsReportsTicketsData ({ commit }) {
    commit('setContactsReportTickets', [])
  }
}

const mutations = {
  setContactsReport: (state, report) => state.report = report,
  setContactsReportTickets: (state, tickets) => state.tickets = tickets,
  setFetchingContactsReport: (state, fetchingContactsReport) => state.fetchingContactsReport = fetchingContactsReport
}

export default {
  state, getters, actions, mutations
}
