// messages module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post } from '../../utils/api'

const state = {
  fetchingMessages: false,
  messages: []
}

const getters = {
  allMessages: state => {
    return state.messages.map(message => {
      const msg = message
      if (msg.contact) {
        msg.contactTo = `(${msg.contact.id} - ${msg.contact.name}) ${msg.to}`
      } else {
        msg.contactTo = msg.to
      }

      switch (msg.status) {
        case 'SUCCESS':
          msg.statusMessage = 'Enviado ao whatsapp'
          break
        case 'WAITING':
          msg.statusMessage = 'Aguardando confirmação para envio'
          break
        case 'CONFIRMED':
          msg.statusMessage = 'Confirmado, Na fila de envio'
          break
        case 'SEEN':
          msg.statusMessage = 'Enviado, Recebido e Lido'
          break
        case 'DISTRIBUTED':
          msg.statusMessage = 'Enviado e recebido'
          break
        case 'SAVED':
          msg.statusMessage = 'Enviado ao whatsapp, porém não recebido'
          break
        case 'CANCEL':
          msg.statusMessage = 'Envio cancelado'
          break
        case 'UPLOADING':
          msg.statusMessage = 'Carregando arquivo'
          break
        case 'WAITING_TICKET':
          msg.statusMessage = 'Aguardando fechamento de ticket'
          break
        default:
          msg.statusMessage = 'Erro no envio'
          break
      }

      return msg
    })
  },
  fetchingMessages: state => state.fetchingMessages
}

const actions = {
  async fetchMessages ({ commit }, data) {
    commit('setFetchingMessages', true)
    const response = await get('messages', data)
    commit('setMessages', response.data)
    commit('setFetchingMessages', false)
  },
  async confirmMessageToSend ({ commit, state }, { message_id }) {
    commit('setFetchingMessages', true)
    const response = await post('messages/confirmMessage', { message_id })
    commit('setMessages', [...state.messages.map(message => message.id === response.data.id ? response.data : message)])
    commit('setFetchingMessages', false)
  },
  async cancelMessageFromSend ({ commit, state }, { message_id }) {
    commit('setFetchingMessages', true)
    const response = await post('messages/cancelMessage', { message_id })
    commit('setMessages', [...state.messages.map(message => message.id === response.data.id ? response.data : message)])
    commit('setFetchingMessages', false)
  },
  async confirmAllMessagesToSend ({ commit }) {
    commit('setFetchingMessages', true)
    const response = await post('messages/confirmAllMessages')
    commit('setMessages', [...state.messages.map(message => {
      const updatedMessage = response.data.find(updatedMessage => updatedMessage.id === message.id)
      if (updatedMessage) {
        return updatedMessage
      }
      return message
    })])
    commit('setFetchingMessages', false)
  },
  async cancelAllPendingMessages ({ commit }) {
    commit('setFetchingMessages', true)
    try {
      const response = await post('messages/cancelAllPendingMessages')
      return response;  
    } finally {
      commit('setFetchingMessages', false)
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async sendMessage ({ }, data) {
    return post('messages', data.message, data.progressEvent)
  },
  async updateMessageLocal ({ commit, state }, message) {
    let messages = state.messages
    const haveMessage = state.messages.find(m => m.id === message.id)
    if (haveMessage) {
      messages = messages.map(msg => msg.id === message.id ? { ...msg, ...message } : msg)
      commit('setMessages', [...messages])
    } else { commit('setMessages', [message, ...messages]) }
  }
}

const mutations = {
  setMessages: (state, messages) => state.messages = messages,
  setFetchingMessages: (state, fetching) => state.fetchingMessages = fetching
}

export default {
  state, getters, actions, mutations
}
