export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif']
export const VIDEO_TYPES = ['mp4', 'video/ogg', 'webm', 'quicktime']
export const AUDIO_TYPES = ['mp3', 'audio/ogg', 'wav', 'mpeg']
export const enumContentType = {
  HEADER: 'HEADER',
  FOOTER: 'FOOTER',
  BODY: 'BODY',
  BUTTONS: 'BUTTONS'
}
export const enumContentFormat = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
  LOCATION: 'LOCATION'
}
export const API_TYPE = {
  OFICIAL: 'OFICIAL',
  WWEBJS: 'WWEBJS'
}

export const Categories = [
  { value: 'MARKETING', label: 'Marketing' },
  { value: 'UTILITY', label: 'Serviços' },
  { value: 'AUTHENTICATION', label: 'Autenticação' }
]

export const Statuses = [
  { value: 'APPROVED', label: 'Aprovado' },
  { value: 'PENDING', label: 'Pendente' },
  { value: 'REJECTED', label: 'Rejeitado' }
]

export const zeepsVariables = {
  '{nome}': 'Nome do usuário',
  '{client_name}': 'Nome do cliente',
  '{client_phone}': 'Telefone do cliente',
  '{telefone}': 'Telefone do usuário',
  '{ticket_id}': 'ID do ticket',
  '{ticket}': 'ID do ticket',
  '{nome_whatsapp}': 'Nome do usuário'
}
