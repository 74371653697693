/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// users module
import { get, post, del } from '../../utils/api'

const state = {
  fetchingUsers: false,
  users: []
}

const getters = {
  allUsers: state => {
    return state.users
  },
  fetchingUsers: state => state.fetchingUsers
}

const actions = {
  async fetchUsers ({ commit }) {
    commit('setFetchingUsers', true)
    const response = await get('users')
    commit('setUsers', response.data)
    commit('setFetchingUsers', false)
  },
  async addUser ({ dispatch }, user) {
    await post('users', user)
    dispatch('fetchUsers')
  },
  async deleteUser ({ dispatch }, user_id) {
    await del(`users/${user_id}`)
    await dispatch('fetchUsers')
  }
}

const mutations = {
  setUsers: (state, users) => state.users = users,
  setFetchingUsers: (state, fetching) => state.fetchingUsers = fetching
}

export default {
  state, getters, actions, mutations
}
