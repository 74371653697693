// tags module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingTemplates: false,
  templates: []
}

const getters = {
  allTemplates: state => {
    return state.templates
  },
  fetchingTemplates: state => state.fetchingTemplates
}

const actions = {
  async fetchTemplates ({ commit }) {
    try {
      commit('setFetchingTemplates', true)
      const response = await get('templates')
      commit('setTemplates', response.data)
    } finally {
      commit('setFetchingTemplates', false)
    }
  },
  async addTemplate ({ dispatch, commit }, template) {
    try {
      commit('setFetchingTemplates', true)
      await post('templates', template)
      dispatch('fetchTemplates')
    } finally {
      commit('setFetchingTemplates', false)
    }
  },
  async deleteTemplate ({ dispatch, commit }, id) {
    try {
      commit('setFetchingTemplates', true)
      await del(`templates/${id}`)
      await dispatch('fetchTemplates')
    } finally {
      commit('setFetchingTemplates', false)
    }
  },
  async updateTemplate ({ dispatch, commit }, template) {
    try {
      commit('setFetchingTemplates', true)
      await put(`templates/${template.id}`, template)
      dispatch('fetchTemplates')
    } finally {
      commit('setFetchingTemplates', false)
    }
  },
  async syncTemplates ({ dispatch, commit }) {
    try {
      commit('setFetchingTemplates', true)
      await post('templates/sync')
      dispatch('fetchTemplates')
    } finally {
      commit('setFetchingTemplates', false)
    }
  }
}

const mutations = {
  setTemplates: (state, tags) => state.templates = tags,
  setFetchingTemplates: (state, fetching) => state.fetchingTemplates = fetching
}

export default {
  state, getters, actions, mutations
}
