<template>
  <div class="card" @click="$emit('cardClick')">
    <header v-if="title" class="card-header">
      <p class="card-header-title">
        <b-icon v-if="icon" :icon="icon" custom-size="default" />
        {{ title }}
        <b-tooltip v-if="iconText" :label="iconText" position="is-right">
          <a
            href="#"
            class="show-data"
            aria-label="show data"
            @click.prevent="showIconClick"
          >
            <b-icon v-if="showIcon && toggleIcon" :icon="showIcon.hidde" custom-size="default"/>
            <b-icon v-if="showIcon && !toggleIcon" :icon="showIcon.show" custom-size="default"/>
          </a>
        </b-tooltip>

        <a
          v-else
          href="#"
          class="show-data"
          aria-label="show data"
          @click.prevent="showIconClick"
        >
          <b-icon v-if="showIcon && toggleIcon" :icon="showIcon.hidde" custom-size="default"/>
          <b-icon v-if="showIcon && !toggleIcon" :icon="showIcon.show" custom-size="default"/>
        </a>
      </p>
      <a
        v-if="headerIcon"
        href="#"
        class="card-header-icon"
        aria-label="more options"
        @click.prevent="headerIconClick"
      >
      </a>
    </header>
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    },
    showIcon: {
      type: Object,
      default: null
    },
    toggleIcon: {
      type: Boolean,
      default: false
    },
    iconText: {
      type: String,
      default: ''
    }
  },
  methods: {
    headerIconClick () {
      this.$emit('header-icon-click')
    },
    showIconClick () {
      this.$emit('show-icon-click')
    }
  }
}
</script>

<style>

.show-data {
  cursor: pointer;
  color: #00A48D;
  font-weight: 600;
  padding-left: 15px;
}

.show-data:hover {
  color: #008e7b;
}

</style>
