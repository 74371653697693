<template>
  <aside v-show="isAsideVisible && isAutenticated" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true" class="imageSpan">
      <span slot="label">
        <img class="logoImage" :src="getUri()" alt="Logo" /></span>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list v-else :key="`${index}-else`" :menu="menuGroup" />
      </template>
    </div>
  </aside>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AsideTools from '@/components/AsideTools'
import AsideMenuList from '@/components/AsideMenuList'
import { apiUri } from '../utils/api'

export default {
  name: 'AsideMenu',
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['isAutenticated']),
    ...mapState({
      isAsideVisible: (state) => state.theme.isAsideVisible
    })
  },
  methods: {
    getUri () {
      return `${apiUri}/appearance/logo`
    }
  }
}
</script>

<style scoped>
.logoImage {
  margin: 8px;
  width: 120px;
}

.imageSpan {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}
</style>
