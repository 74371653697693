/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// settings module
import { get, put, post, del } from '../../utils/api'

const state = {
  fetchingIntegrations: false,
  fetchingSystems: false,
  systems: []
}

const getters = {
  allSystems: state => {
    return state.systems
  },
  fetchingIntegrations: state => state.fetchingIntegrations,
  fetchingSystems: state => state.fetchingSystems
}

const actions = {
  async fetchSystems ({ commit }) {
    commit('setFetchingIntegrations', true)
    const response = await get('systems')
    commit('setSystems', response.data)
    commit('setFetchingIntegrations', false)
  },
  async updateSystem ({ commit, dispatch }, data) {
    commit('setFetchingIntegrations', true)
    await put('systems', data)
    dispatch('fetchSettings')
  },
  async addIntegration ({ commit }, data) {
    commit('setFetchingIntegrations', true)
    try {
      await post('integrations/', data)
    } finally {
      commit('setFetchingIntegrations', false)
    }
  },
  async updateIntegration ({ commit }, data) {
    commit('setFetchingIntegrations', true)
    try {
      await put(`integrations/${data.id}`, data)
    } finally {
      commit('setFetchingIntegrations', false)
    }
  },
  async fetchAllIntegrations ({ commit }, data) {
    let result = []
    commit('setFetchingIntegrations', true)
    try {
      result = await get('integrations')
      result = result.data
    } finally {
      commit('setFetchingIntegrations', false)
    }

    return result
  },
  async fetchSystemDetail ({ commit }, id) {
    commit('setFetchingIntegrations', true)
    const response = await get(`systems/${id}`)
    commit('setFetchingIntegrations', false)
    return response.data
  },
  async addSystem ({ commit, dispatch }, data) {
    commit('setFetchingSystems', true)
    try {
      await post('systems/', data)
      await dispatch('fetchSystems')
    } finally {
      commit('setFetchingSystems', false)
    }
  },
  async deleteSystems ({ dispatch }, id) {
    await del(`systems/${id}`)
    await dispatch('fetchSystems')
  }
}

const mutations = {
  setSystems: (state, systems) => state.systems = systems,
  setFetchingIntegrations: (state, fetching) => state.fetchingIntegrations = fetching,
  setFetchingSystems: (state, fetching) => state.fetchingSystems = fetching
}

export default {
  state, getters, actions, mutations
}
