// options module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingOptions: false,
  options: []
}

const getters = {
  allOptions: state => {
    return state.options
  },
  fetchingOptions: state => state.fetchingOptions
}

const actions = {
  async fetchOptions ({ commit }) {
    try {
      commit('setFetchingOptions', true)
      const response = await get('options')
      commit('setOptions', response.data)
    } finally {
      commit('setFetchingOptions', false)
    }
  },
  async addOption ({ dispatch }, option) {
    await post('options', option)
    dispatch('fetchOptions')
  },
  async deleteOption ({ dispatch }, option_id) {
    await del(`options/${option_id}`)
    await dispatch('fetchOptions')
  },
  async updateOption ({ dispatch, commit }, option) {
    try {
      commit('setFetchingOptions', true)
      await put(`options/${option.id}`, option)
      dispatch('fetchOptions')
    } finally {
      commit('setFetchingOptions', false)
    }
  }
}

const mutations = {
  setOptions: (state, options) => state.options = options,
  setFetchingOptions: (state, fetching) => state.fetchingOptions = fetching
}

export default {
  state, getters, actions, mutations
}
