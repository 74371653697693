// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingAttendantsReport: false,
  report: {},
  tickets: []
}

const getters = {
  getAttendantsReports: state => {
    return state.report
  },
  getAttendantsReportsTickets: state => {
    return state.tickets
  },
  fetchingAttendantsReports: state => {
    return state.fetchingAttendantsReport
  }
}

const actions = {
  async fetchAttendantsReports ({ commit }, data) {
    commit('setFetchingAttendantsReport', true)
    const response = await get('reports/attendants', data)
    commit('setAttendantsReport', response.data)
    commit('setFetchingAttendantsReport', false)
  },
  async fetchAttendantsReportsTickets ({ commit }, data) {
    commit('setFetchingAttendantsReport', true)
    const response = await get('reports/attendants/tickets', data)
    commit('setAttendantsReportTickets', response.data)
    commit('setFetchingAttendantsReport', false)
  },
  async clearAttendantsReportsTicketsData ({ commit }) {
    commit('setAttendantsReportTickets', [])
  }
}

const mutations = {
  setAttendantsReport: (state, report) => state.report = report,
  setAttendantsReportTickets: (state, tickets) => state.tickets = tickets,
  setFetchingAttendantsReport: (state, fetchingAttendantsReport) => state.fetchingAttendantsReport = fetchingAttendantsReport
}

export default {
  state, getters, actions, mutations
}
