// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingMenuReport: false,
  report: {},
  tickets: []
}

const getters = {
  getMenuReport: state => {
    return state.report
  },
  getMenuReportTickets: state => {
    return state.tickets
  },
  fetchingMenuReport: state => {
    return state.fetchingMenuReport
  }
}

const actions = {
  async fetchMenuReport ({ commit }, data) {
    commit('setFetchingMenuReport', true)
    const response = await get('reports/menu', data)
    commit('setMenuReport', response.data)
    commit('setFetchingMenuReport', false)
  },
  async fetchMenuReportTickets ({ commit }, data) {
    commit('setFetchingMenuReport', true)
    const response = await get('reports/menu/tickets', data)
    commit('setMenuReportTickets', response.data)
    commit('setFetchingMenuReport', false)
  },
  async clearMenuReportTicketsData ({ commit }) {
    commit('setMenuReportTickets', [])
  }
}

const mutations = {
  setMenuReport: (state, report) => state.report = report,
  setMenuReportTickets: (state, tickets) => state.tickets = tickets,
  setFetchingMenuReport: (state, fetchingMenuReport) => state.fetchingMenuReport = fetchingMenuReport
}

export default {
  state, getters, actions, mutations
}
