// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, put, del } from '../../utils/api'

const state = {
  fetchingBusinessHours: false,
  businessHours: []
}

const getters = {
  allBusinessHours: state => {
    return state.businessHours
  },
  allEnabledBusinessHours: state => {
    return state.businessHours.filter(b => b.enabled)
  },
  fetchingBusinessHours: state => state.fetchingBusinessHours
}

const actions = {
  async fetchBusinessHours ({ commit }) {
    try {
      commit('setFetchingBusinessHours', true)
      const response = await get('business-hours')
      commit('setBusinessHours', response.data)
    } finally {
      commit('setFetchingBusinessHours', false)
    }
  },
  async addBusinessHours ({ dispatch }, businessHours) {
    const newBusinessHours = await post('business-hours', businessHours)
    dispatch('fetchBusinessHours')
    return newBusinessHours
  },
  async updateBusinessHours ({ dispatch, commit }, data) {
    try {
      commit('setFetchingBusinessHours', true)
      await put(`business-hours/${data.id}`, data.body)
      dispatch('fetchBusinessHours')
    } finally {
      commit('setFetchingBusinessHours', false)
    }
  },
  async deleteBusinessHours ({ dispatch }, id) {
    await del(`business-hours/${id}`)
    await dispatch('fetchBusinessHours')
  }
}

const mutations = {
  setBusinessHours: (state, businessHours) => state.businessHours = businessHours,
  setFetchingBusinessHours: (state, fetching) => state.fetchingBusinessHours = fetching
}

export default {
  state, getters, actions, mutations
}
