/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// settings module
import { get, post } from '../../utils/api'

const state = {
  fetchingGenSettings: false,
  genSettings: {}
}

const getters = {
  allGenSettings: state => state.genSettings,
  fetchingGenSettings: state => state.fetchingGenSettings
}

const actions = {
  async fetchGenSettings ({ commit }) {
    commit('setFetchingGenSettings', true)
    const response = await get('settings/general')
    commit('setGenSettings', response.data)
    commit('setFetchingGenSettings', false)
  },
  async saveGenSettings ({ commit, dispatch }, data) {
    commit('setFetchingGenSettings', true)
    await post('settings/general', data)
    dispatch('fetchGenSettings')
  }
}

const mutations = {
  setGenSettings: (state, settings) => state.genSettings = settings,
  setFetchingGenSettings: (state, fetching) => state.fetchingGenSettings = fetching
}

export default {
  state, getters, actions, mutations
}
