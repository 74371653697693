// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingSatisfactionSurveyReport: false,
  report: {},
  tickets: []
}

const getters = {
  getSatisfactionSurveyReport: state => {
    return state.report
  },
  getSatisfactionSurveyReportTickets: state => {
    return state.tickets
  },
  fetchingSatisfactionSurveyReport: state => {
    return state.fetchingSatisfactionSurveyReport
  }
}

const actions = {
  async fetchSatisfactionSurveyReport ({ commit }, data) {
    commit('setFetchingSatisfactionSurveyReport', true)
    const response = await get('reports/surveys', data)
    commit('setSatisfactionSurveyReport', response.data)
    commit('setFetchingSatisfactionSurveyReport', false)
  },
  async fetchSatisfactionSurveyReportTickets ({ commit }, data) {
    commit('setFetchingSatisfactionSurveyReport', true)
    const response = await get('reports/surveys/tickets', data)
    commit('setSatisfactionSurveyReportTickets', response.data)
    commit('setFetchingSatisfactionSurveyReport', false)
  },
  async clearSatisfactionSurveyReportTicketsData ({ commit }) {
    commit('setSatisfactionSurveyReportTickets', [])
  }
}

const mutations = {
  setSatisfactionSurveyReport: (state, report) => state.report = report,
  setSatisfactionSurveyReportTickets: (state, tickets) => state.tickets = tickets,
  setFetchingSatisfactionSurveyReport: (state, fetchingSatisfactionSurveyReport) => state.fetchingSatisfactionSurveyReport = fetchingSatisfactionSurveyReport
}

export default {
  state, getters, actions, mutations
}
