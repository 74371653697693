// login module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { post, get } from '../../utils/api'
import { getAppVersion, getGitHash } from '../../utils/app-version.js'

const status = [
  {
    key: 'offline',
    name: 'Offline',
    color: 'red'
  },
  {
    key: 'online',
    name: 'Online',
    color: 'green'
  },
  {
    key: 'invisible',
    name: 'Ausente',
    color: 'orange'
  }
]

const state = {
  userId: localStorage.getItem('userId') || '',
  name: localStorage.getItem('name') || '',
  isAdmin: localStorage.getItem('isAdmin') || '',
  token: localStorage.getItem('token') || '',
  isSupervisor: localStorage.getItem('isSupervisor') || '',
  isSupervisorAllDepartments: localStorage.getItem('isSupervisorAllDepartments') || '',
  apiVersion: '',
  userDepartments: localStorage.getItem('userDepartments') || '',
  status: localStorage.getItem('status') || status[0],
  allStatus: status
}

const getters = {
  isAutenticated: state => {
    return !!state.token
  },
  getToken: state => {
    return state.token
  },
  getName: state => {
    return state.name
  },
  getUserId: state => {
    return state.userId
  },
  getIsSupervisor: state => {
    try {
      return typeof state.isSupervisor === 'string' ? JSON.parse(state.isSupervisor.toLowerCase()) : state.isSupervisor
    } catch (error) {
      return false
    }
  },
  getIsSupervisorAllDepartments: state => {
    return typeof state.isSupervisorAllDepartments === 'string' ? JSON.parse(state.isSupervisorAllDepartments.toLowerCase()) : state.isSupervisorAllDepartments
  },
  getIsAdmin: state => {
    return state.isAdmin && typeof state.isAdmin === 'string' ? JSON.parse(state.isAdmin.toLowerCase()) : state.isAdmin
  },
  getVersion () {
    return `${getAppVersion()}.${getGitHash().substring(0, 5)}`
  },
  getApiVersion: state => {
    return state.apiVersion
  },
  getUserDepartments: state => {
    return state.userDepartments
  },
  getStatus: state => {
    return status.find(el => el.key === state.status)
  },
  getAllStatus: state => {
    return state.allStatus
  }
}

const actions = {
  async fetchDoLogin ({ commit }, { username, password }) {
    const response = await post('sessions', { username, password })
    commit('setToken', response.data.token)
    commit('setUserId', response.data.user.id)
    commit('setName', response.data.user.name)
    commit('setIsAdmin', response.data.user.isAdmin)
    commit('setIsSupervisor', response.data.user.isSupervisor)
    commit('setIsSupervisorAllDepartments', response.data.user.isSupervisorAllDepartments)
    commit('setUserDepartments', response.data.user.departments || [])
    commit('setStatus', response.data.user.status)
    this._vm.$socket.initSocket(response.data.token)
  },
  async fetchUserDetails ({ commit }, token) {
    commit('setToken', token)
    const response = await post('attendants/details')
    commit('setUserId', response.data.details.user.id)
    commit('setName', response.data.details.user.name || response.data.details.user.username)
    commit('setIsAdmin', response.data.details.user.admin || false)
    commit('setIsSupervisor', response.data.details.user.supervisor || false)
    commit('setIsSupervisorAllDepartments', response.data.details.user.supervisor_all_departments || false)
    commit('setUserDepartments', response.data.details.user.departments || [])
    commit('setStatus', response.data.details.user.status)
  },
  async deleteToken ({ commit }) {
    await commit('setToken', '')
    await commit('setUserId', '')
    await commit('setName', '')
    await commit('setIsAdmin', '')
    await commit('setIsSupervisor', '')
    await commit('setIsSupervisorAllDepartments', '')
    await commit('setUserDepartments', [])
    await commit('setStatus', '')
  },
  async fetchApiVersion ({ commit }) {
    const response = await get('ping/version')
    commit('setApiVersion', response.data.version)
  },
  async setUserStatus ({ commit }, data) {
    const response = await post('attendants/status', { attendant_id: state.userId, status: data, set_date: new Date(Date.now()) })
    commit('setStatus', response.data.status)
  }
}

const mutations = {
  setToken: (state, token) => {
    const url = new URL(process.env.VUE_APP_GTCHAT_URL)
    const path = url.pathname
    const domain = url.hostname.split('.').slice(1).join('.')
    state.token = token
    localStorage.setItem('token', token)

    document.cookie = `token-${url.hostname + path}=${token};domain=${domain}; path=/; SameSite=None; Secure;`
  },
  setUserId: (state, userId) => {
    state.userId = userId
    localStorage.setItem('userId', userId)
  },
  setIsAdmin: (state, isAdmin) => {
    state.isAdmin = isAdmin
    localStorage.setItem('isAdmin', isAdmin)
  },
  setIsSupervisor: (state, isSupervisor) => {
    state.isSupervisor = isSupervisor
    localStorage.setItem('isSupervisor', isSupervisor)
  },
  setIsSupervisorAllDepartments: (state, isSupervisorAllDepartments) => {
    state.isSupervisorAllDepartments = isSupervisorAllDepartments
    localStorage.setItem('isSupervisorAllDepartments', isSupervisorAllDepartments)
  },
  setName: (state, name) => {
    state.name = name
    localStorage.setItem('name', name)
  },
  setApiVersion: (state, apiVersion) => {
    state.apiVersion = apiVersion
    localStorage.setItem('apiVersion', name)
  },
  setUserDepartments: (state, userDepartments) => {
    state.userDepartments = userDepartments
    localStorage.setItem('userDepartments', userDepartments)
  },
  setStatus: (state, status) => {
    state.status = status
    localStorage.setItem('status', status)
  }
}

export default {
  state, getters, actions, mutations
}
