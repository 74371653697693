/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
// import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Default title tag */
// const defaultDocumentTitle = 'Zeeps'

import Socket from './socket/socket'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(async to => {
  store.commit('asideMobileStateToggle', false)

  let appName = store.getters.getAppName

  if (!appName) {
    await store.dispatch('fetchAppearance')
    appName = store.getters.getAppName
  }

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${appName}`
  } else {
    document.title = appName
  }
})

Vue.config.productionTip = false
Vue.prototype.$socket = new Socket()

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
