import axios from 'axios'
import store from '../store/index'

const apiUri = process.env.VUE_APP_GTCHAT_URL

const getCancelable = async function (resource, cancelToken, params) {
  return await axios.get(`${apiUri}/${resource}`, {
    params,
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    },
    cancelToken
  })
}

const get = async function (resource, params) {
  return await axios.get(`${apiUri}/${resource}`, {
    params,
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    }
  })
}

const pureGet = async function (resource, params) {
  return await axios.get(`${resource}`, {
    params,
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    }
  })
}

const pureBlobGet = async function (resource, params) {
  return await axios.get(`${resource}`, {
    responseType: 'blob',
    params,
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    }
  })
}

const post = async function (resource, params, onUploadProgress) {
  return await axios.post(`${apiUri}/${resource}`, params, {
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    },
    onUploadProgress: onUploadProgress
  })
}

const put = async function (resource, params) {
  if (params instanceof FormData) {
    return await axios.put(`${apiUri}/${resource}`, params, {
      headers: {
        Authorization: `Basic ${store.state.login.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
  } else {
    return await axios.put(`${apiUri}/${resource}`, params, {
      headers: {
        Authorization: `Basic ${store.state.login.token}`
      }
    })
  }
}

const del = async function (resource, params) {
  return await axios.delete(`${apiUri}/${resource}`, {
    params,
    headers: {
      Authorization: `Basic ${store.state.login.token}`
    }
  })
}

const getUrl = function (resource) {
  return `${apiUri}/${resource}`
}

export { get, post, put, del, apiUri, getCancelable, getUrl, pureGet, pureBlobGet }
