import { io } from 'socket.io-client'

export default class Socket {
    static socket = null

    getSocket () {
        return this.socket
    }

    getSocketStatus() {
        if (!this.socket) return 'disconnected'
        if (this.socket?.disconnected) return 'disconnected'
        if (this.socket?.connected) return 'connected'
        if (this.socket?.connecting) return 'connecting'
        if (this.socket?.reconnecting) return 'reconnecting'
        return 'disconnected'
    }

     initSocket (token) {
        if (this.socket) this.socket.disconnect()
        this.socket = io(process.env.VUE_APP_SOCKETIO_URL ?? process.env.VUE_APP_GTCHAT_URL, {
            reconnectionDelayMax: 10000,
            query: {
                token: token,
            },
            auth: {
                token: token,
            },
            path: `${process.env.VUE_APP_SOCKETIO_PATH ?? '/socket.io'}`
        });

        this.socket.on('connect', () => {
            console.log('socket connected')
        })

        this.socket.on('disconnect', () => {
            console.log('socket disconnected')
        })

        this.socket.on('connect_error', (err) => {
            console.log('socket connect_error', err)
        })
    }

    connect () {
        if (this.socket) this.socket.connect()
    }

    disconnect () {
        if (this.socket) this.socket.disconnect()
    }

    on (event, callback) {
        if (this.socket) this.socket.on(event, callback)
    }

    emit (event, data) {
        if (this.socket) this.socket.emit(event, data)
    }

    off (event, callback) {
        if (this.socket) this.socket.off(event, callback)
    }

    removeAllListeners (event) {
        if (this.socket) this.socket.removeAllListeners(event)
    }

    removeListener (event, callback) {
        if (this.socket) this.socket.removeListener(event, callback)
    }

    hasListeners (event) {
        return this.socket.hasListeners(event)
    }

    listeners (event) {
        return this.socket.listeners(event)
    }

    once (event, callback) {
        if (this.socket) this.socket.once(event, callback)
    }
}
