export const chartColors = {
  default: {
    primary: '#00D1B2',
    info: '#209CEE',
    danger: '#FF3860'
  }
}

export const baseChartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
    onHover: function (e) {
      e.target.style.cursor = 'pointer'
    }
  },
  hover: {
    onHover: function (e) {
      var point = this.getElementAtEvent(e)
      if (point.length) e.target.style.cursor = 'pointer'
      else e.target.style.cursor = 'default'
    }
  },
  // tooltips: {
  //   mode: 'dataset'
  // },
  responsive: true
  // onClick: (point, event) => {
  //   const item = event[0]
  //   EventBus.$emit("chartClickEvent", item._index);
  // }
}

export const chartOptionsMain = {
  ...baseChartOptions,
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest'
  },
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        suggestedMin: 0, // minimum will be 0, unless there is a lower value.
        // OR //
        beginAtZero: true // minimum value will be 0.
      }
    }]
  }
  // scales: {
  //   yAxes: [
  //     {
  //       // barPercentage: 1.6,
  //       gridLines: {
  //         drawBorder: false,
  //         color: 'rgba(29,140,248,0.0)',
  //         zeroLineColor: 'transparent'
  //       },
  //       ticks: {
  //         padding: 20,
  //         fontColor: '#9a9a9a'
  //       }
  //     }
  //   ],

  //   xAxes: [
  //     {
  //       barPercentage: 1.6,
  //       gridLines: {
  //         drawBorder: false,
  //         color: 'rgba(225,78,202,0.1)',
  //         zeroLineColor: 'transparent'
  //       },
  //       ticks: {
  //         padding: 20,
  //         fontColor: '#9a9a9a'
  //       }
  //     }
  //   ]
  // }
}
