<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Detalhes do atendimento selecionado</p>
            <button class="button is-pulled-right" type="button" @click="closeModal">
              <span class="icon">
                <i class="fas fa-times-circle"></i>
              </span>
            </button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-centered">
              <div class="column is-one-quarter">
                <b-image v-if="imageSrc" :src="imageSrc" alt="The Buefy Logo" ratio="300by300"></b-image>
              </div>
            </div>
            <b-field grouped>
              <b-field label="ID do ticket" expanded>
                <b-input type="text" :value="ticket.id" readonly>
                </b-input>
              </b-field>
              <b-field label="Telefone Zeeps" expanded>
                <b-input type="text" :value="phone_from" readonly>
                </b-input>
              </b-field>
              <b-field v-if="ticket.attendant" label="Atendente do ticket" expanded>
                <b-input type="text" :value="ticket.attendant.name" readonly>
                </b-input>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field v-if="!ticket.is_group" label="Telefone do contato">
                <b-input type="text" :value="ticket.client_phone" readonly>
                </b-input>
              </b-field>
              <b-field v-else label="É grupo">
                <b-input type="text" value="Sim" readonly>
                </b-input>
              </b-field>
              <b-field label="Nome no whatsapp" expanded>
                <b-input type="text" :value="ticket.client_name" readonly>
                </b-input>
              </b-field>
              <b-field label="Contato" v-if="ticket.contact" expanded>
                <b-input type="text" :value="getContactDetails" readonly>
                </b-input>
              </b-field>
            </b-field>
            <div v-if="!ticket.contact && !ticket.is_group">
              <b-message type="is-danger">
                O numero {{ ticket.client_phone }} não possui nenhum contato registrado!
              </b-message>
            </div>
            <hr>
            <b-field v-if="ticket.contact" grouped>
              <b-field label="CPF/CNPJ">
                <b-input type="text" :value="ticket.contact.cpf_cnpj" readonly>
                </b-input>
              </b-field>
              <b-field label="Endereço" expanded>
                <b-input type="text" :value="ticket.contact.address" readonly>
                </b-input>
              </b-field>
              <b-field label="Complemento" v-if="ticket.contact" expanded>
                <b-input type="text" :value="ticket.contact.address_2" readonly>
                </b-input>
              </b-field>
            </b-field>
            <b-field v-if="ticket.contact" grouped>
              <b-field label="Bairro">
                <b-input type="text" :value="ticket.contact.neighborhood" readonly>
                </b-input>
              </b-field>
              <b-field label="Cidade" expanded>
                <b-input type="text" :value="ticket.contact.city" readonly>
                </b-input>
              </b-field>
              <b-field label="Estado" v-if="ticket.contact" expanded>
                <b-input type="text" :value="ticket.contact.district" readonly>
                </b-input>
              </b-field>
              <b-field label="Aniversário" v-if="ticket.contact" expanded>
                <b-input type="text" :value="ticket.contact.birthday ? formatDate(ticket.contact.birthday) : null"
                  readonly>
                </b-input>
              </b-field>
            </b-field>
            <b-field label="Informações adicionais" v-if="ticket.contact">
              <editor api-key="no-api-key" v-model="additional_info" output-format="text" :init="{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }" />
            </b-field>
            <div v-if="ticket.contact">
              <hr>
              <b-field label="Tags do contato" expanded>
                <multiselect v-model="selectedTags" :multiple="true" :disabled="true" :options="tags" label="title"
                  track-by="id" select-label="" deselect-label="">
                  <template slot="placeholder">Este contato não possui tags</template>
                </multiselect>
              </b-field>
            </div>

            <button v-if="showMessagesOption && !loadMessages" class="button is-pulled-right is-success" type="button"
              @click="loadMessages = true">Carregar últimas 15 mensagens do ticket</button>
            <div v-show="loadMessages">
              <b-field label="Mensagens do ticket">
                <chat-window class="chat-window" :single-room="true" :rooms="[ticket]" :current-user-id="getUserId"
                  :messages="messages" height="calc(100vh - 80px)" :loading-rooms="false" :rooms-loaded="true"
                  :messages-loaded="messagesLoaded" :show-footer="false" :message-actions="[]"
                  :show-reaction-emojis="false" @open-file="openFile" @fetch-messages="fetchMessages" :text-messages="{
                    ROOMS_EMPTY: 'Sem tickets',
                    ROOM_EMPTY: 'Nenhum ticket selecionado',
                    NEW_MESSAGES: 'Novas mensagens',
                    MESSAGE_DELETED: 'Esta mensagem foi apagada',
                    MESSAGES_EMPTY: 'Nenhuma mensagem',
                    CONVERSATION_STARTED: 'A conversa começou em:',
                    TYPE_MESSAGE: 'Digite sua mensagem',
                    SEARCH: 'Procurar ticket',
                    IS_ONLINE: 'Está online',
                    LAST_SEEN: 'Última visualização ',
                    IS_TYPING: 'Está digitando...',
                  }" />
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-pulled-right" type="button" @click="closeModal">Fechar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import ChatWindow from 'vue-advanced-chat'
import multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import { get, pureBlobGet, pureGet } from '../../utils/api'
import mixin from '../../utils/mixins'
import { isAudioFile, isVideoFile, isImageFile } from '../../utils/util'
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ModalTicketDetails',
  components: {
    ChatWindow,
    multiselect,
    Editor
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    ticket: {
      type: Object,
      required: true
    },
    showMessagesOption: {
      type: Boolean,
      required: true
    },
    tags: {
      type: Array,
      required: true
    },
    contactTags: {
      type: Array,
      required: true
    }
  },
  mixins: [mixin],
  data() {
    return {
      messages: [],
      messagesLoaded: false,
      loadMessages: false,
      selectedTags: [],
      additional_info: this.ticket.contact ? this.ticket.contact.additional_info : '',
      phone_from: '',
      imageSrc: ''
    }
  },
  computed: {
    ...mapGetters(['allContacts', 'allAttendants', 'getToken', 'getUserId']),
    getContactDetails() {
      return `${this.ticket.contact.id} - ${this.ticket.contact.name}`
    }
  },
  watch: {
    additional_info(newInfo, oldInfo) {
      const info = {
        id: this.ticket.contact.id,
        name: this.ticket.contact.name,
        address: this.ticket.contact.address,
        address_2: this.ticket.contact.address_2,
        neighborhood: this.ticket.contact.neighborhood,
        city: this.ticket.contact.city,
        district: this.ticket.contact.district,
        birthday: this.ticket.contact.birthday,
        cpf_cnpj: this.ticket.contactcpf_cnpj,
        email: this.ticket.contact.email,
        instagram_profile: this.ticket.contact.instagram_profile,
        contact_type: this.ticket.contact.contact_type,
        additional_info: newInfo
      }
      this.$emit('updateContact', info)
    }
  },
  methods: {
    async getImage(id) {
      if (!id) return
      const contactImage = await get(`contacts/image/${id}`)
      return contactImage ? `data:image/png;base64,${contactImage.data}` : ''
    },
    closeModal() {
      this.$emit('closeModal')
    },
    async openFile({ message, action }) {
      if (message.files[0].url.indexOf('message-details') > -1) {
        let messageDetails = await pureGet(message.files[0].url)
        messageDetails = messageDetails.data

        if (messageDetails.file_base64) {
          message.files[0].url = `data:${messageDetails.file_mimetype};base64,${messageDetails.file_base64}`
          message.files[0].type = messageDetails.file_mimetype
          if ((message.files[0].name && !isImageFile(message.files[0].name) && !isVideoFile(message.files[0].name) && !isAudioFile(message.files[0].name))) {
            const a = document.createElement('a')
            a.href = message.files[0].url
            let ext
            try {
              ext = message.files[0].type.split('/')[1]
            } catch (error) {
              ext = ''
            }
            a.setAttribute('target', '_blank')
            a.download = `${message.files[0].name ?? 'download.' + ext}`
            a.click()
          }
        }
      } else {
        const messageDetails = await pureBlobGet(message.files[0].url)

        if (messageDetails.data) {
          const binaryData = [messageDetails.data]
          this.saveFile(new Blob(binaryData, { type: message.files[0].customtype }), message.files[0].name)
        }
      }
    },
    saveFile(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        setTimeout(() => {
          URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      }
    },
    async fetchMessages() {
      this.messagesLoaded = false
      const r = await get(`reports/messages/${this.ticket.id}/1`)
      this.messages = [...r.data.reverse().map(message => this.adaptMessageToMessage(message)), ...this.messages]
      this.messagesLoaded = true
    },
    getSelectedTags() {
      this.selectedTags = this.tags.filter((tag) => {
        return this.contactTags.some(contactTag => {
          return tag.id === contactTag.tag_id
        })
      })
    },
    setPhoneFrom() {
      let phone = ''
      if (this.ticket.setting.name) {
        phone = phone + this.ticket.setting.name + ' - '
      }
      if (this.ticket.setting.phone) {
        phone = phone + this.ticket.setting.phone
      } else {
        phone = this.ticket.ww_phone ?? ''
      }

      this.phone_from = phone
    }
  },
  async created() {
    this.getSelectedTags()
    this.setPhoneFrom()

    this.imageSrc = await this.getImage(this.ticket.contact_id)
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.modal-card-foot {
  justify-content: flex-end;
}

.vac-room-item-open {
  border-color: aquamarine;
  border-width: 1px;
  border-style: solid;
  // animation: blinker 1.5s linear infinite;
}

.vac-container-scroll__margin-bottom {
  margin-bottom: 60px;
}

.vac-box-footer__hidden {
  display: none !important;
}

.select-attendant-div {
  background-color: aquamarine;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}
</style>
