/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// chat module
import { post } from '../../utils/api'

const state = {
}

const getters = {
}

const actions = {
  async createNewTicket ({ commit }, data) {
    return post('tickets/createTicketWithNumber', data).then((result) => {
      return result.data
    }).catch((err) => {
      if (err.response) {
        throw err.response.data
      }
      throw err
    })
  },
  async transferTicket ({ commit }, data) {
    await post(`tickets/transferTicket/${data.ticket_id}`, {
      department_id_to: data.department_id,
      attendant_id_to: data.attendant_id,
      department_id_from: data.department_from
    }).catch((err) => {
      if (err.response) {
        throw err.response.data
      }
      throw err
    })
  },
  async getTicketDetails ({ commit }, data) {
    await post(`tickets/transferTicket/${data.ticket_id}`, {
      department_id_to: data.department_id,
      attendant_id_to: data.attendant_id,
      department_id_from: data.department_from
    }).catch((err) => {
      if (err.response) {
        throw err.response.data
      }
      throw err
    })
  },
}

const mutations = {
}

export default {
  state, getters, actions, mutations
}
