// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingDashboard: false,
  dashboard: {},
  graphics: [],
  ticketsReport: [],
  ticketsByDepartment: [],
  ticketsByAttendant: [],
  ticketsByStatus: [],
  ticketsToday: []
}

const getters = {
  dashboard: state => {
    return state.dashboard
  },
  graphics: state => {
    const graph = []
    for (let i = 1; i <= 12; i++) {
      const graphValue = state.graphics.find(g => g.month === i.toString())
      graph.push(graphValue ?? { tickets_qty: 0 })
    }
    return graph
  },
  ticketsReport: state => {
    return state.ticketsReport
  },
  getAllTicketsCount: state => {
    return state.dashboard.ticketsCount
  },
  getOpenTicketsCount: state => {
    return state.dashboard.ticketsOpenCount
  },
  getTicketsTodayCount: state => {
    return state.dashboard.ticketsTodayCount
  },
  getTicketsAttendingCount: state => {
    return state.dashboard.ticketsAttendingCount
  },
  getTicketsByDepartment: state => {
    return state.ticketsByDepartment
  },
  getTicketsByAttendant: state => {
    return state.ticketsByAttendant
  },
  getTicketsByStatus: state => {
    return state.ticketsByStatus
  },
  fetchingDashboard: state => state.fetchingDashboard
}

const actions = {
  async fetchDashboard ({ commit }) {
    commit('setFetchingDashboard', true)
    const response = await get('dashboard')
    commit('setDashboard', response.data)
    commit('setFetchingDashboard', false)
  },
  async fetchTicketsReport ({ commit }, data) {
    commit('setFetchingDashboard', true)
    const response = await get('reports/tickets', data)
    commit('setTicketsReport', response.data)
    commit('setFetchingDashboard', false)
  },
  async fetchTicketsByDepartment ({ commit }, data) {
    commit('setFetchingDashboard', true)
    const response = await get('dashboard/tickets30DaysByDepartment', data)
    commit('setTicketsByDepartment', response.data)
    commit('setFetchingDashboard', false)
  },
  async fetchTicketsByAttendant ({ commit }, data) {
    commit('setFetchingDashboard', true)
    const response = await get('dashboard/tickets30DaysByAttendant', data)
    commit('setTicketsByAttendant', response.data)
    commit('setFetchingDashboard', false)
  },
  async fetchTicketsByStatus ({ commit }, data) {
    commit('setFetchingDashboard', true)
    const response = await get('dashboard/tickets30DaysByStatus', data)
    commit('setTicketsByStatus', response.data)
    commit('setFetchingDashboard', false)
  },
  async fetchGraphics ({ commit }) {
    commit('setFetchingDashboard', true)
    const response = await get('dashboard/graphics')
    commit('setGraphics', response.data.tickets)
    commit('setFetchingDashboard', false)
  }
}

const mutations = {
  setDashboard: (state, dashboard) => state.dashboard = dashboard,
  setGraphics: (state, graphics) => state.graphics = graphics,
  setFetchingDashboard: (state, fetching) => state.fetchingDashboard = fetching,
  setTicketsReport: (state, ticketsReport) => state.ticketsReport = ticketsReport,
  setTicketsByDepartment: (state, tickets) => state.ticketsByDepartment = tickets,
  setTicketsByAttendant: (state, tickets) => state.ticketsByAttendant = tickets,
  setTicketsByStatus: (state, tickets) => state.ticketsByStatus = tickets
}

export default {
  state, getters, actions, mutations
}
