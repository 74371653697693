import { IMAGE_TYPES, VIDEO_TYPES, AUDIO_TYPES } from './constants'
import { v4 as uuidv4 } from 'uuid';

function checkMediaType (types, fileName) {
  if (!fileName) return false
  return types.some(t => fileName.toLowerCase().includes(t))
}

export function isImageFile (file) {
  return checkMediaType(IMAGE_TYPES, file)
}

export function isVideoFile (file) {
  return checkMediaType(VIDEO_TYPES, file)
}

export function isImageVideoFile (file) {
  return checkMediaType(IMAGE_TYPES, file) || checkMediaType(VIDEO_TYPES, file)
}

export function isAudioFile (file) {
  return checkMediaType(AUDIO_TYPES, file)
}

export function isLargeFile (fileSize) {
  if (fileSize > 20000000) {
    return true
  }
  return false
}

export function isMediaMessage (messageType) {
  return ['image', 'video', 'audio', 'document', 'sticker', 'ptt'].includes(messageType)
}

export function getTemporaryId () {
  return uuidv4();
}
