// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingContacts: false,
  contacts: [],
  fetchingContactAttendants: false,
  contactAttendants: []
}

const getters = {
  allContacts: state => {
    return state.contacts
  },
  fetchingContacts: state => state.fetchingContacts,
  allContactAttendants: state => {
    return state.contactAttendants
  },
  fetchingContactAttendants: state => state.fetchingContactAttendants
}

const actions = {
  async fetchContacts ({ commit }, data) {
    try {
      commit('setFetchingContacts', true)
      const response = await get('contacts', data)
      commit('setContacts', response.data.results)

      return response.data;
    } finally {
      commit('setFetchingContacts', false)
    }
  },
  async fetchContactsWithFilter ({ commit }, { limit, filter }) {
    const response = await get(`contacts?limit=${limit}&filter=${filter}`)
    return response
  },
  async fetchContactAttendants ({ commit, dispatch }, contact_id) {
    try {
      commit('setFetchingContactAttendants', true)
      const response = await dispatch('fetchAndResturnContactAttendants', contact_id)
      commit('setContactAttendants', response)
    } finally {
      commit('setFetchingContactAttendants', false)
    }
  },
  async fetchAndResturnContactAttendants ({ commit }, contact_id) {
      const response = await get(`contacts/contactAttendants/${contact_id}`, { get_details: true })
      return response.data
  },
  async addContact ({ dispatch }, contact) {
    const newContact = await post('contacts', contact)
    dispatch('fetchContacts')
    return newContact
  },
  async resetPrivacyTermsAllContacts ({ dispatch, commit }) {
    try {
      commit('setFetchingContactAttendants', true)
      await post('/contacts/set-privacy-terms-pending-all-contacts')
      dispatch('fetchContacts')
    } finally {
      commit('setFetchingContactAttendants', false)
    }
  },
  async deleteContact ({ dispatch }, contact) {
    await del(`contacts/${contact.id}/${contact.transferTo}`)
    setTimeout(async () => {
      await dispatch('fetchContacts')
    }, 100);
  },
  async updateContact ({ dispatch, commit }, contact) {
    try {
      commit('setFetchingContacts', true)
      await put(`contacts/${contact.id}`, contact)
      dispatch('fetchContacts')
    } finally {
      commit('setFetchingContacts', false)
    }
  },
  async syncWhatsappContacts ({ dispatch, commit }, data) {
    try {
      commit('setFetchingContacts', true)
      await post(`contacts/sync-whatsapp-contacts/${data.canOverlap}`, data.phones)
      await dispatch('fetchContacts')
    } finally {
      commit('setFetchingContacts', false)
    }
  },
  async addContactAttendants ({ dispatch }, links) {
    const newContactAttendants = await post('contacts/contactAttendants', links)
    return newContactAttendants
  }
}

const mutations = {
  setContacts: (state, contacts) => state.contacts = contacts,
  setFetchingContacts: (state, fetching) => state.fetchingContacts = fetching,
  setContactAttendants: (state, contactAttendants) => state.contactAttendants = contactAttendants,
  setFetchingContactAttendants: (state, fetchingContactAttendants) => state.fetchingContactAttendants = fetchingContactAttendants
}

export default {
  state, getters, actions, mutations
}
