// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingContactsGroups: false,
  contactsGroups: []
}

const getters = {
  allContactsGroups: state => {
    return state.contactsGroups
  },
  fetchingContactsGroups: state => state.fetchingContactsGroups
}

const actions = {
  async fetchContactsGroups ({ commit }) {
    try {
      commit('setFetchingContactsGroups', true)
      const response = await get('groups')
      commit('setContactsGroups', response.data)
    } finally {
      commit('setFetchingContactsGroups', false)
    }
  },
  async fetchContactGroupDetail ({ commit }, contact_group_id) {
    const response = await get(`groups/${contact_group_id}`)
    return response.data
  },
  async addContactGroup ({ dispatch }, group) {
    await post('groups', group)
    dispatch('fetchContactsGroups')
  },
  async deleteContactGroup ({ dispatch }, contact_group_id) {
    await del(`groups/${contact_group_id}`)
    await dispatch('fetchContactsGroups')
  },
  async updateContactGroup ({ dispatch, commit }, group) {
    try {
      commit('setFetchingContactsGroups', true)
      await put(`groups/${group.id}`, group)
      dispatch('fetchContactsGroups')
    } finally {
      commit('setFetchingContactsGroups', false)
    }
  }
}

const mutations = {
  setContactsGroups: (state, contactsGroups) => state.contactsGroups = contactsGroups,
  setFetchingContactsGroups: (state, fetching) => state.fetchingContactsGroups = fetching
}

export default {
  state, getters, actions, mutations
}
