/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
// permissions module
import { get, put, post, del } from '../../utils/api'

const state = {
  fetchingPermissions: false,
  permissions: [],
  availablePermissionsItems: []
}

const getters = {
  allPermissions: state => {
    return state.permissions
  },
  availablePermissionsItems: state => state.availablePermissionsItems,
  fetchingPermissions: state => state.fetchingPermissions
}

const actions = {
  async fetchPermissions({ commit }) {
    commit('setFetchingPermissions', true)
    const response = await get('permissions')
    commit('setPermissions', response.data)
    commit('setFetchingPermissions', false)
  },
  async fetchAvailablePermissionItems({ commit }) {
    commit('setFetchingPermissions', true)
    const response = await get('permissions/available-permissions-items')
    commit('setAvailablePermissionsItems', response.data)
    commit('setFetchingPermissions', false)
  },
  async updatePermission({ commit, dispatch }, data) {
    commit('setFetchingPermissions', true)
    await put('permissions', data).catch((error) => {
      commit('setFetchingPermissions', false)
      throw error
    })
    dispatch('fetchPermissions')
  },
  async addPermission({ commit }, data) {
    commit('setFetchingPermissions', true)
    try {
      await post('permissions', data)
    } finally {
      commit('setFetchingPermissions', false)
    }
  },
  async fetchAllPermissions({ commit }) {
    let result = []
    commit('setFetchingPermissions', true)
    try {
      result = await get('permissions')
      result = result.data
    } finally {
      commit('setFetchingPermissions', false)
    }

    return result
  },
  async fetchPermissionDetail({ commit }, id) {
    commit('setFetchingPermissions', true)
    const response = await get(`permissions/${id}`)
    commit('setFetchingPermissions', false)
    return response.data
  },
  async addPermission({ commit, dispatch }, data) {
    commit('setFetchingPermissions', true)
    try {
      await post('permissions/', data)
      await dispatch('fetchPermissions')
    } finally {
      commit('setFetchingPermissions', false)
    }
  },
  async deletePermission({ dispatch }, id) {
    await del(`permissions/${id}`)
    await dispatch('fetchPermissions')
  }
}

const mutations = {
  setAvailablePermissionsItems: (state, availablePermissionsItems) => (state.availablePermissionsItems = availablePermissionsItems),
  setPermissions: (state, permissions) => (state.permissions = permissions),
  setFetchingPermissions: (state, fetching) => (state.fetchingPermissions = fetching)
}

export default {
  state,
  getters,
  actions,
  mutations
}