import Vue from 'vue'
import Vuex from 'vuex'

import products from './modules/products'
import messages from './modules/messages'
import contacts from './modules/contacts'
import login from './modules/login'
import qrcode from './modules/qrcode'
import users from './modules/users'
import settings from './modules/settings'
import dashboard from './modules/dashboard'
import attendants from './modules/attendants'
import notifications from './modules/notifications'
import warnings from './modules/warnings'
import departments from './modules/departments'
import theme from './modules/theme'
import chat from './modules/chat'
import menuOptions from './modules/menuOptions'
import ticketsCloseReason from './modules/ticketsCloseReason'
import defaultMessages from './modules/defaultMessages'
import contactsGroups from './modules/contactsGroups'
import tags from './modules/tags'
import integrations from './modules/integrations'
import wppGroups from './modules/wppGroups'
import menuReport from './modules/menuReport'
import departmentsReport from './modules/departmentsReport'
import contactsReport from './modules/contactsReport'
import attendantsReport from './modules/attendantsReport'
import businessHours from './modules/businessHours'
import satisfactionSurveys from './modules/satisfactionSurveys'
import satisfactionSurveyReport from './modules/satisfactionSurveyReport'
import genSettings from './modules/genSettings'
import attendantsDashboard from './modules/attendantsDashboard'
import contactsFieldsQuestions from './modules/contactsFieldsQuestions'
import templates from './modules/templates'
import permissions from './modules/permissions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    products,
    messages,
    contacts,
    login,
    qrcode,
    users,
    settings,
    dashboard,
    attendants,
    notifications,
    warnings,
    departments,
    theme,
    chat,
    menuOptions,
    ticketsCloseReason,
    defaultMessages,
    contactsGroups,
    integrations,
    tags,
    wppGroups,
    menuReport,
    departmentsReport,
    contactsReport,
    attendantsReport,
    businessHours,
    satisfactionSurveys,
    satisfactionSurveyReport,
    genSettings,
    attendantsDashboard,
    contactsFieldsQuestions,
    templates,
    permissions
  }
})
