// dashboard module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get } from '../../utils/api'

const state = {
  fetchingAttendantsDashboard: false,
  fetchingAttendantsTickets: false,
  attendantsDashboard: [],
  departmentsAttendants: [],
  attendantsTicketsCount: [],
  attendantsTickets: []
}

const getters = {
  getAttendantsDashboard: state => {
    return state.attendantsDashboard
  },
  getDepartmentsAttendantsDashboard: state => {
    return state.departmentsAttendants
  },
  getAttendantsTicketsCount: state => {
    return state.attendantsTicketsCount
  },
  getAttendantsTickets: state => {
    return state.attendantsTickets
  }
}

const actions = {
  async fetchAttendantsDashboard ({ commit }) {
    try {
      commit('setFetchingAttendantsDashboard', true)
      const response = await get('attendants-dashboard')
      commit('setAttendantsDashboard', response.data)
    } finally {
      commit('setFetchingAttendantsDashboard', false)
    }
  },
  async fetchDepartmentsAttendants ({ commit }) {
    try {
      commit('setFetchingAttendantsDashboard', true)
      const response = await get('attendants-dashboard/departments')
      commit('setDepartmentsAttendants', response.data)
    } finally {
      commit('setFetchingAttendantsDashboard', false)
    }
  },
  async fetchAttendantsTicketsCount ({ commit }, status) {
    try {
      commit('setFetchingAttendantsDashboard', true)
      const response = await get(`attendants-dashboard/tickets-count/${status}`)
      commit('setAttendantsTicketsCount', response.data)
    } finally {
      commit('setFetchingAttendantsDashboard', false)
    }
  },
  async fetchAttendantsTickets ({ commit }, data) {
    commit('setFetchingAttendantsTickets', true)
    const response = await get('attendants-dashboard/tickets', data)
    commit('setAttendantsTickets', response.data)
    commit('setFetchingAttendantsTickets', false)
  }
}

const mutations = {
  setFetchingAttendantsDashboard: (state, fetching) => state.fetchingAttendantsDashboard = fetching,
  setFetchingAttendantsTickets: (state, fetching) => state.fetchingAttendantsTickets = fetching,
  setAttendantsDashboard: (state, attendants) => state.attendantsDashboard = attendants,
  setDepartmentsAttendants: (state, departments) => state.departmentsAttendants = departments,
  setAttendantsTicketsCount: (state, attendants) => state.attendantsTicketsCount = attendants,
  setAttendantsTickets: (state, tickets) => state.attendantsTickets = tickets
}

export default {
  state, getters, actions, mutations
}
