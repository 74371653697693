<template>
  <nav v-show="isNavBarVisible && isAutenticated" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" v-if="!isChatScreen" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <a class="navbar-item" @click.prevent="menuToggleMobile" v-if="isChatScreen">
        <router-link slot="right" to="/" class="button is-primary">
          Dashboard
        </router-link>
      </a>
      <div class="navbar-item no-right-space-touch">
        <section>
          <b-field grouped group-multiline>
            <div class="control">
              <b-taglist attached>
                <b-tag>
                  <b-icon type="is-success" icon="phone" size="is-small">
                  </b-icon>
                  <b-tooltip position="is-right" size="is-small" type="is-success" multilined>
                    {{ getWarnings.connectedCount }}
                    <template v-slot:content>
                      <div>
                        Números Conectados
                        <span v-for="(phoneConnected, item) in getWarnings.phonesConnected" :key="item">
                          {{ phoneConnected }}
                        </span>
                      </div>
                    </template>
                  </b-tooltip>
                </b-tag>
                <b-tag>
                  <b-icon type="is-danger" icon="phone-remove" size="is-small">
                  </b-icon>
                  <b-tooltip position="is-bottom" size="is-small" type="is-danger is-light" multilined>
                    {{ getWarnings.disconnectedCount }}
                    <template v-slot:content>
                      Números desconectados
                      <span v-for="(phoneDisconnected, item) in getWarnings.phonesDisconnected" :key="item">
                        {{ phoneDisconnected }}
                      </span>
                    </template>
                  </b-tooltip>
                </b-tag>
                <b-tag v-if="isAutenticated && getWarnings.hasOwnProperty('isConnected') && !getWarnings.isConnected"
                  type="is-danger">Nenhum Whatsapp conectado!</b-tag>
              </b-taglist>
            </div>
          </b-field>
        </section>
      </div>
    </div>
    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuNavBarActive }">
      <div class="navbar-end">
        <div class="navbar-item no-right-space-touch">
          <section>
            <b-field grouped group-multiline>
              <div class="control">
                <b-taglist attached>
                  <b-tag>
                    <b-icon type="is-success" icon="book" size="is-small">
                    </b-icon>
                    v{{ getVersion }} | v{{ getApiVersion }}
                  </b-tag>
                </b-taglist>
              </div>
            </b-field>
          </section>
        </div>

        <nav-bar-menu v-if="!getIsAdmin" class="has-divider has-user-avatar" :isActive="isUserDropdownActive"
          @toggleDropdown="toggleUserDropdown">
          <user-avatar v-if="false" />
          <div class="is-user-name">
            <b-icon v-if="userStatus" icon="circle" custom-size="default" :style="{ color: userStatus.color }"></b-icon>
            <span>{{ getName }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown status-dropdown">
            <a class="navbar-link is-arrowless" @click="toggleStatusDropdown">
              <div class="is-user-name">
                <b-icon v-if="userStatus" icon="circle" custom-size="default"
                  :style="{ color: userStatus.color }"></b-icon>
                <span v-if="userStatus">{{ userStatus.name }}</span>
              </div>
              <b-icon :icon="toggleStatusDropdownIcon" custom-size="default" />
            </a>
            <div v-if="userStatus" v-show="isStatusDropdownActive" class="status">
              <a class="status-option" v-for="status in statuses" v-bind:key="status.key"
                @click="changeStatus(status.key)">
                <b-icon icon="circle" custom-size="default" :style="{ color: status.color }"></b-icon>
                <span>{{ status.name }}</span>
              </a>
            </div>

            <a class="navbar-item" @click="profile">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>Meu perfil</span>
            </a>

            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Sair</span>
            </a>
          </div>
        </nav-bar-menu>
        <nav-bar-menu v-else class="has-divider has-user-avatar" :isActive="isUserDropdownActive"
          @toggleDropdown="toggleUserDropdown">
          <user-avatar v-if="false" />
          <div class="is-user-name">
            <span>{{ getName }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item" @click="profile">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>Meu perfil</span>
            </a>
            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Sair</span>
            </a>
          </div>
        </nav-bar-menu>
        <!-- <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar v-if="false" />
          <div class="is-user-name">
            <span>{{ getName }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default" />
              <span>My Profile</span>
            </router-link>
            <a class="navbar-item">
              <b-icon icon="settings" custom-size="default"></b-icon>
              <span>Settings</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="email" custom-size="default"></b-icon>
              <span>Messages</span>
            </a> -->
        <!-- <hr class="navbar-divider" />
            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Sair</span>
            </a>
          </div>
        </nav-bar-menu>-->
        <!-- <a
          href="https://justboil.me/bulma-admin-template/one"
          class="navbar-item has-divider is-desktop-icon-only"
          title="About"
        >
          <b-icon icon="help-circle-outline" custom-size="default" />
          <span>About</span>
        </a> -->
        <a class="navbar-item is-desktop-icon-only" title="Sair" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>Sair</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBarMenu from '@/components/NavBarMenu'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false,
      isUserDropdownActive: false,
      isStatusDropdownActive: false
    }
  },
  computed: {
    ...mapGetters(['getVersion', 'getApiVersion']),
    isChatScreen () {
      return this.$route.name === 'chat'
    },
    menuNavBarToggleIcon () {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    statuses () {
      if (this.getAllStatus && this.userStatus) {
        return this.getAllStatus.filter(el => el.key !== this.userStatus.key)
      } else return null
    },
    userStatus () {
      if (this.getIsAdmin) {
        return null
      }

      if (this.isAutenticated && this.getToken) {
        this.fetchUserDetails(this.getToken)
        return this.getStatus
      }

      return null
    },
    toggleStatusDropdownIcon () {
      return this.isStatusDropdownActive ? 'chevron-up' : 'chevron-down'
    },
    ...mapState({
      isNavBarVisible: state => state.theme.isNavBarVisible
    }),
    ...mapState({
      isAsideMobileExpanded: state => state.theme.isAsideMobileExpanded
    }),
    ...mapGetters(['getName', 'isAutenticated', 'getAllStatus', 'getStatus']),
    ...mapGetters(['isAutenticated', 'getIsAdmin', 'getWarnings', 'getToken', 'getUnreadNotifications'])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })

    this.$socket.on('attendant_status', (data) => {
      this.$buefy.toast.open({
        message: `Status alterado para ${data.status}`,
        type: data.status === 'online' ? 'is-success' : 'is-danger',
        position: 'is-top-right',
        duration: 5000
      })
      this.fetchUserDetails(this.getToken)
    })
  },
  methods: {
    ...mapActions(['deleteToken', 'setUserStatus', 'fetchUserDetails']),
    ...mapActions(['fetchUnreadNotifications']),
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = !this.isMenuNavBarActive
    },
    async logout () {
      if (!this.getIsAdmin) {
        this.setUserStatus('offline')
      }
      await this.deleteToken()
      this.$router.push({ path: '/login' })
    },
    async profile () {
      await this.fetchUserDetails(this.getToken)
      this.$router.push({ path: '/profile' })
    },
    async changeStatus (status) {
      await this.setUserStatus(status)
      this.toggleUserDropdown()

      if (this.$route.name === 'UserOffline' && (this.userStatus.key === 'online' || this.userStatus.key === 'invisible')) {
        this.$router.push({ path: '/chat' })
      } else if (this.$route.name === 'chat' && this.userStatus.key === 'offline') {
        this.$router.push({ path: '/userOffline' })
      }
    },
    toggleStatusDropdown () {
      this.isStatusDropdownActive = !this.isStatusDropdownActive
    },
    toggleUserDropdown () {
      this.isUserDropdownActive = !this.isUserDropdownActive
      if (!this.isUserDropdownActive) this.isStatusDropdownActive = false
    }
  }
}
</script>

<style>
.is-user-name span.icon,
.navbar-dropdown .status .icon {
  margin-right: 0px !important;
  line-height: normal;
}

.status-dropdown .navbar-link {
  background-color: #ffffff !important;
}

.status {
  background-color: hsl(0deg, 0%, 98%);
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(24, 28, 33, 0.06)
}

.status a:hover {
  background-color: hsl(0deg, 0%, 95%);
}

.status-option {
  display: block;
  padding: 5px 18px;
  color: hsl(0deg, 0%, 29%)
}
</style>
