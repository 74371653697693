import format from 'date-fns/format'
import { getUrl, get } from '../utils/api'
import { isImageFile, isMediaMessage } from '../utils/util'
import vCard from 'vcf'

const LOADING_IMG_URL = `${process.env.VUE_APP_BASE_PATH ?? '/'}spinning_wheel_2.gif`

// import { utcToZonedTime } from 'date-fns-tz'
// import { addHours } from 'date-fns'

const mixins = {
  methods: {
    formatDate: (date) => {
      return format(new Date(date), 'dd/MM/yyyy')
    },
    formatShortDate: (date) => {
      return format(new Date(date), 'dd/MM')
    },
    formatTime: (date) => {
      return format(new Date(date), 'HH:mm')
    },
    formatDateTime: (date) => {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    datesAreOnSameDay: (date1, date2) => {
      return (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate())
    },
    async showMessageOk(title, message, confirmCallback, cancelCallback) {
      return this.$buefy.dialog.confirm({
        title: title,
        message: message,
        confirmText: 'Ok',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Fechar',
        onConfirm: (value) => {
          if (confirmCallback) confirmCallback(value)
        },
        onCancel: (value) => {
          if (cancelCallback) cancelCallback(value)
        }
      })
    },
    reloadPage() {
      this.$router.go()
    },
    adaptMessageToMessage (newMessage, userId, bypassDuplicityVerification = false) {
      const date = newMessage.whatsapp_timestamp || newMessage.created_at || new Date()

      if (!newMessage.id) {
        newMessage.id = Math.floor(Math.random() * 1000000)
        newMessage.from = userId
        newMessage.timestamp = `${this.formatDate(date)} - ${this.formatTime(date)}`
      } else {
        if (this.messages.find(msg => msg.id === newMessage.id) && !bypassDuplicityVerification) return undefined

        newMessage.timestamp = `${this.formatDate(date)} - ${this.formatTime(date)}`
      }

      if (newMessage.is_note) {
        newMessage.timestamp = `Anotação de transferência - ${newMessage.timestamp}`
      }

      if (/BEGIN:VCARD((.|\n)*?)END:VCARD/gm.test(newMessage.message)) {
        const cardContact = this.adaptCardContactMessage(newMessage.message)
        newMessage.message = cardContact[0]
        newMessage.sharedContact = cardContact[1]
      }

      if (newMessage.deleted && !newMessage.waiting) {
        if (newMessage.sharedContact) {
          newMessage.message = '~Mensagem apagada:~\n~*Contato*~\n'
          if (Array.isArray(newMessage.sharedContact)) {
            newMessage.sharedContact.forEach(contact => {
              newMessage.message += '~_Nome_: ' + contact.name + '~\n~_Telefone_: ' + contact.phone + '~\n'
            })
          } else {
            newMessage.message += '~_Nome_: ' + newMessage.sharedContact.name + '~\n~_Telefone_: ' + newMessage.sharedContact.phone + '~\n'
          }
        } else if (newMessage.file_mimetype && !newMessage.message) {
          newMessage.message = '~Mensagem apagada~'
        } else {
          const str = newMessage.message.lastIndexOf('\n')
          if (newMessage.message.length === str + 1) {
            newMessage.message = newMessage.message.substr(0, str)
          }
          if (newMessage.message.search('\n') !== -1) {
            newMessage.message = '~Mensagem apagada:~\n~' + newMessage.message.replace(/(?:\r\n|\r|\n)/g, '~\n~') + '~'
            while (newMessage.message.search('~~') !== -1) {
              newMessage.message = newMessage.message.replace('~~', '')
            }
          } else {
            newMessage.message = '~Mensagem apagada:~\n~' + newMessage.message + '~'
          }
        }
      }

      const ret = {
        ...newMessage,
        _id: newMessage.id,
        content: newMessage.message,
        senderId: newMessage.is_group ? userId : newMessage.from,
        deleted: false,
        isDeleted: newMessage.deleted,
        username:
          newMessage.whatsapp_sender_name || newMessage.whatsapp_message_phone
            ? `${newMessage.whatsapp_sender_name ?? ''}  ${newMessage.whatsapp_message_phone
            }`
            : null,
        disable_actions: true,
        disable_reactions: true,
        saved: newMessage.saved && !(newMessage.failure || newMessage.failed) || true,
        distributed: newMessage.distributed,
        seen: newMessage.seen,
        failure: newMessage.failure || newMessage.failed,
      }

      if (
        newMessage.whatsapp_quoted_message_id &&
        newMessage.whatsapp_quoted_message
      ) {
        ret.replyMessage = {
          _id: newMessage.whatsapp_quoted_message_id,
          content: /BEGIN:VCARD((.|\n)*?)END:VCARD/gm.test(newMessage.whatsapp_quoted_message.message) ? this.adaptCardContactMessage(newMessage.whatsapp_quoted_message.message)[0] : newMessage.whatsapp_quoted_message.message,
          sender_id: ret.senderId
        }

        if (newMessage.whatsapp_quoted_message) this.adaptFileMessage(newMessage.whatsapp_quoted_message, ret.replyMessage, true)
      } else if (newMessage.quotedMsg) {
        const message = (newMessage.quotedMsg.messageDB && (newMessage.quotedMsg.messageDB.message || newMessage.quotedMsg.messageDB.message === ''))
          ? newMessage.quotedMsg.messageDB.message
          : newMessage.quotedMsg.body ?? newMessage.quotedMsg.message
        ret.replyMessage = {
          _id: newMessage.quotedMsg.messageDB.whatsapp_quoted_message_id,
          content: /BEGIN:VCARD((.|\n)*?)END:VCARD/gm.test(message) ? this.adaptCardContactMessage(message)[0] : message,
          sender_id: ret.senderId
        }
        if (newMessage.quotedMsg.messageDB && (newMessage.quotedMsg.messageDB.file_name || newMessage.quotedMsg.messageDB.file_mimetype || newMessage.quotedMsg.messageDB.file)) {
          this.adaptFileMessage(newMessage.quotedMsg.messageDB, ret.replyMessage, true)
        }
      }

      if (newMessage.file_mimetype || newMessage.file_id || isMediaMessage(newMessage.message_type)) {
        this.adaptFileMessage(newMessage, ret)
      }

      if (newMessage.message_type && newMessage.message_type.toLowerCase() === 'ciphertext') {
        ret.deleted = true
        ret.waiting = true
      }

      return ret
    },
    adaptCardContactMessage (message) {
      const cardObject = vCard().parse(message.replaceAll('\n', '\r\n'))
      let sharedContact = []
      if (Array.isArray(cardObject.data.fn)) {
        let contactData
        if (cardObject.data.fn.length === cardObject.data.tel.length) {
          message = '*Contatos* \n'
          for (let i = 0; i < cardObject.data.fn.length; i++) {
            const parsedName = cardObject.data.fn[i]._data
            const parsedPhone = cardObject.data.tel[i]._data
            if (parsedName && parsedPhone) {
              message += `_Nome_: ${parsedName}\n`
              message += `_Telefone_: ${parsedPhone}\n\n`
              contactData = { name: parsedName, phone: parsedPhone.replace(/\+|-| /g, '') }
            }
            sharedContact.push(contactData)
          }
        } else {
          const cards = message.split('END:VCARD BEGIN:VCARD')
          message = '*Contatos* \n'

          cards.forEach(card => {
            if (card.search('BEGIN:VCARD') === -1) {
              card = 'BEGIN:VCARD' + card
            }
            if (card.search('END:VCARD') === -1) {
              card = card + 'END:VCARD'
            }

            const cardObject = vCard().parse(card.replaceAll('\n', '\r\n'))

            const parsedName = cardObject.data.fn._data
            message += `_Nome_: ${parsedName}\n`

            if (Array.isArray(cardObject.data.tel)) {
              for (let i = 0; i < cardObject.data.tel.length; i++) {
                contactData = { name: parsedName, phone: '' }
                const parsedPhone = cardObject.data.tel[i]._data
                if (parsedPhone) {
                  message += (i + 1) === cardObject.data.tel.length ? `_Telefone_: ${parsedPhone}\n\n` : `_Telefone_: ${parsedPhone}\n`
                  contactData.phone = parsedPhone.replace(/\+|-| /g, '')
                }
                sharedContact.push(contactData)
              }
            } else {
              contactData = { name: parsedName, phone: '' }
              const parsedPhone = cardObject.data.tel._data
              contactData.phone = parsedPhone.replace(/\+|-| /g, '')
              message += `_Telefone_: ${parsedPhone}\n\n`
              sharedContact.push(contactData)
            }
          })
        }
      } else {
        const parsedName = cardObject.data.fn._data
        message = `*Contato* \n_Nome_: ${parsedName}\n\n`

        if (Array.isArray(cardObject.data.tel)) {
          const sharedContact = []
          for (let i = 0; i < cardObject.data.tel.length; i++) {
            const contactData = { name: parsedName, phone: '' }
            const parsedPhone = cardObject.data.tel[i]._data
            if (parsedPhone) {
              message += `_Telefone_: ${parsedPhone}\n`
              contactData.phone = parsedPhone.replace(/\+|-| /g, '')
            }
            sharedContact.push(contactData)
          }
        } else {
          sharedContact = { name: parsedName, phone: '' }
          const parsedPhone = cardObject.data.tel._data
          sharedContact.phone = parsedPhone.replace(/\+|-| /g, '')
          message += `_Telefone_: ${parsedPhone}\n`
        }
      }
      return [message, sharedContact]
    },
    async adaptFileMessage (message, ret, reply = false) {
      const msgId = message.id
      const fileName = message.file_name !== '' ? message.file_name : null
      if (message.file_id && message.file) {
        ret.files = [{
          name: message.file.originalname,
          type: message.file.mimetype,
          audio: message.file.mimetype.indexOf('audio/ogg') > -1,
          url: getUrl(`tickets/file/${message.id}`)
        }]
      } else if (message.file_mimetype) {
        const type = isImageFile(fileName ?? message.file_mimetype) ? message.file_mimetype : 'pdf'
        const url = isImageFile(fileName ?? message.file_mimetype) ? LOADING_IMG_URL : getUrl(`tickets/message-details/${msgId}`)
        const audio = reply
          ? message.file_mimetype.indexOf('audio/ogg') > -1
            ? 'audio/ogg'
            : null
          : null
        ret.files = [{
          name: fileName,
          type: audio || type,
          // type: message.file_mimetype,
          // audio: audio,
          url
        }]

        if (isImageFile(fileName) || isImageFile(message.file_mimetype)) {
          if (!fileName) {
            let ext
            try {
              ext = ret.files[0].type.split('/')[1]
            } catch (error) {
              ext = ''
            }

            ret.files[0].name = `download.${ext}`
          } else {
            ret.files[0].name = fileName
          }

          get(`tickets/message-details/${msgId}`).then(messageDetails => {
            const messageD = messageDetails.data

            ret.files[0].url = `data:${messageD.file_mimetype};base64,${messageD.file_base64}`
          }).catch(err => {
            console.warn(err)
            ret.files[0].type = 'pdf'
            ret.files[0].url = getUrl(`tickets/message-details/${msgId}`)
          })
        }
      } else if (isMediaMessage(message.message_type)) {
        let type
        switch (message.message_type) {
          case 'document':
            type = 'application/pdf'
            break
          case 'video':
            type = 'application/mp4'
            break
          case 'image':
            type = 'application/jpeg'
            break
          default:
            type = 'application/pdf'
            break
        }

        ret.files = [{
          name: message.message || 'download',
          type: type,
          url: getUrl(`tickets/file/${message.id}`)
        }]
      }
    }
  }
}

export default mixins
