<template>
  <div id="app" :class="!isAutenticated ? 'no-padding-left' : ''">
    <div style="min-height: 80vh;">
      <nav-bar v-if="this.getToken" />
      <aside-menu :menu="menu" />
      <router-view />
    </div>
    <footer-bar />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'

export default {
  name: 'Home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      settings: []
    }
  },
  computed: {
    ...mapGetters(['isAutenticated', 'getIsAdmin', 'getToken', 'getUnreadNotifications', 'allSettingsEnabled']),
    menu () {
      return [
        'Dashboard',
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Tickets'
          },
          {
            label: 'Reports',
            subLabel: 'Submenus Example',
            icon: 'view-list',
            menu: [
              {
                to: '/reports-menu',
                // href: '#void',
                label: 'Opções de menu'
              },
              {
                to: '/departments-reports',
                // href: '#void',
                label: 'Departamentos'
              },
              {
                to: '/attendants-reports',
                // href: '#void',
                label: 'Atendentes'
              },
              {
                to: '/contacts-reports',
                // href: '#void',
                label: 'Contatos'
              },
              {
                to: '/surveys-reports',
                // href: '#void',
                label: 'Pesq. satisfação'
              }
            ]
          },
          {
            to: '/attendantsDashboard',
            icon: 'account',
            label: 'Atendentes'
          },
          {
            to: '/messages',
            icon: 'calendar',
            label: 'Mens. agendadas'
          },
          {
            to: '/ticketsReport',
            icon: 'forum',
            label: 'Relat. tickets'
          }
        ],
        'Chat',
        [
          {
            to: '/chat',
            label: 'Chats',
            icon: 'chat'
            // updateMark: true
          },
          {
            to: '/grupos-wpp',
            label: 'Grupos Wpp',
            icon: 'whatsapp'
          },
          {
            to: '/templates',
            label: 'Templates',
            icon: 'whatsapp'
          }
        ],
        'Cadastros',
        [
          {
            to: '/departamentos',
            label: 'Departamentos',
            icon: 'account-box-multiple'
          },
          {
            to: '/atendentes',
            label: 'Atendentes',
            icon: 'account'
          },
          {
            to: '/opcoes',
            label: 'Opções de menu',
            icon: 'menu'
          },
          {
            label: 'Contatos',
            subLabel: 'Submenus Example',
            icon: 'account-supervisor',
            menu: [
              {
                to: '/contatos',
                label: 'Cadastro',
                icon: 'account-supervisor'
              },
              {
                to: '/grupos',
                label: 'Grupos de contatos',
                icon: 'account-supervisor'
              },
              {
                to: '/tags',
                label: 'Tags',
                icon: 'tag-multiple'
              }
            ]
          },
          {
            to: '/razoes',
            label: 'Confg. fech. tickets',
            icon: 'close-circle-outline'
          },
          {
            to: '/business-hours',
            label: 'Horários de Func.',
            icon: 'clock'
          },
          {
            to: '/satisfaction-surveys',
            label: 'Pesq. satisfação',
            icon: 'clipboard-edit'
          },
          {
            to: '/default-messages',
            label: 'Mensg. predefinidas',
            icon: 'message-plus'
          }
        ],
        'Configurações',
        [
          {
            to: '/qrcode',
            label: 'Mapear QRCode',
            icon: 'qrcode'
          },
          {
            label: 'Configurações',
            subLabel: 'Submenus Example',
            icon: 'phone-settings',
            menu: this.settings
          },
          {
            label: 'Config. Gerais',
            to: '/configuracoes-gerais',
            icon: 'cog'
          },
          {
            to: '/permissions',
            label: 'Permissões',
            icon: 'link-lock'
          },
          {
            to: '/integracoes',
            label: 'Integrações',
            icon: 'all-inclusive'
          }
        ]
      ]
    }
  },
  methods: {
    ...mapActions(['fetchApiVersion', 'fetchSettings']),
    async phoneSettings () {
      await this.fetchSettings()
      const config = []
      for (const [h, v] of Object.entries(this.allSettingsEnabled)) {
        if (typeof v === 'object' && v) {
          config.push(v)
        }
      }
      this.settings = config.map((setting, index) => {
        const c = {}
        c.to = `/configuracoes/${setting.phone}`
        c.label = setting.name ? setting.name : setting.phone
        c.icon = 'phone'
        return c
      })
    }
  },
  async created () {
    this.fetchApiVersion()
  },
  mounted () {
    if (this.isAutenticated) {
      this.phoneSettings()

      if (!this.$socket.getSocket()) {
        this.$socket.initSocket(this.getToken)
      }
    }
  },
  watch: {
    $route () {
      if (this.isAutenticated) {
        this.phoneSettings()

        if (!this.$socket.getSocket()) {
          this.$socket.initSocket(this.getToken)
        }
      }
    }
  }
}
</script>

<style lang="css">
.no-padding-left {
  padding-left: 0px !important;
}
</style>
