// contacts module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, put, del } from '../../utils/api'

const state = {
  fetchingSatisfactionSurveys: false,
  satisfactionSurveys: [],
  fetchingSentSurveys: false,
  sentSurveys: []
}

const getters = {
  allSatisfactionSurveys: state => {
    return state.satisfactionSurveys.map(s => {
      s.enabled = s.enabled === 1
      s.always_send = s.always_send === 1
      return s
    })
  },
  allSurveysEnabled: state => {
    return state.satisfactionSurveys.filter(survey => survey.enabled)
  },
  fetchingSatisfactionSurveys: state => state.fetchingSatisfactionSurveys,
  allSentSurveys: state => {
    return state.sentSurveys.map(s => s.id)
  },
  fetchingSentSurveys: state => state.fetchingSentSurveys
}

const actions = {
  async fetchSatisfactionSurveys ({ commit }) {
    try {
      commit('setFetchingSatisfactionSurveys', true)
      const response = await get('satisfaction-surveys')
      commit('setSatisfactionSurveys', response.data)
    } finally {
      commit('setFetchingSatisfactionSurveys', false)
    }
  },
  async addSurvey ({ dispatch }, survey) {
    const newSurvey = await post('satisfaction-surveys', survey)
    dispatch('fetchSatisfactionSurveys')
    return newSurvey
  },
  async updateSurvey ({ dispatch, commit }, data) {
    try {
      commit('setFetchingSatisfactionSurveys', true)
      await put(`satisfaction-surveys/${data.id}`, data)
      dispatch('fetchSatisfactionSurveys')
    } finally {
      commit('setFetchingSatisfactionSurveys', false)
    }
  },
  async deleteSurvey ({ dispatch }, id) {
    await del(`satisfaction-surveys/${id}`)
    await dispatch('fetchSatisfactionSurveys')
  },
  async fetchSentSurveys ({ commit }) {
    try {
      commit('setFetchingSentSurveys', true)
      const response = await get('satisfaction-surveys/sent')
      commit('setSentSurveys', response.data)
    } finally {
      commit('setFetchingSentSurveys', false)
    }
  }
}

const mutations = {
  setSatisfactionSurveys: (state, surveys) => state.satisfactionSurveys = surveys,
  setFetchingSatisfactionSurveys: (state, fetching) => state.fetchingSatisfactionSurveys = fetching,
  setSentSurveys: (state, surveys) => state.sentSurveys = surveys,
  setFetchingSentSurveys: (state, fetching) => state.fetchingSentSurveys = fetching
}

export default {
  state, getters, actions, mutations
}
