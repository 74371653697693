// tags module
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import { get, post, del, put } from '../../utils/api'

const state = {
  fetchingTags: false,
  tags: [],
  fetchingEnabledTags: false,
  enabledTags: [],
  fetchingContactTags: false,
  contactTags: []
}

const getters = {
  allTags: state => {
    return state.tags
  },
  fetchingTags: state => state.fetchingTags,
  allEnabledTags: state => {
    return state.enabledTags
  },
  fetchingEnabledTags: state => state.fetchingEnabledTags,
  allContactTags: state => {
    return state.contactTags
  },
  fetchingContactTags: state => state.fetchingContactTags
}

const actions = {
  async fetchTags ({ commit }) {
    try {
      commit('setFetchingTags', true)
      const response = await get('tags')
      commit('setTags', response.data)
    } finally {
      commit('setFetchingTags', false)
    }
  },
  async fetchEnabledTags ({ commit }) {
    try {
      commit('setFetchingEnabledTags', true)
      const response = await get('tags/enabled', true)
      commit('setEnabledTags', response.data)
    } finally {
      commit('setFetchingEnabledTags', false)
    }
  },
  async addTag ({ dispatch }, tag) {
    await post('tags', tag)
    dispatch('fetchTags')
  },
  async deleteTag ({ dispatch }, tag_id) {
    await del(`tags/${tag_id}`)
    await dispatch('fetchTags')
  },
  async updateTag ({ dispatch, commit }, tag) {
    try {
      commit('setFetchingTags', true)
      await put(`tags/${tag.id}`, tag)
      dispatch('fetchTags')
    } finally {
      commit('setFetchingTags', false)
    }
  },
  async fetchContactTags ({ commit }, contact_id) {
    try {
      commit('setFetchingContactTags', true)
      const response = await get(`tags/contactTags/${contact_id}`)
      commit('setContactTags', response.data)
    } finally {
      commit('setFetchingContactTags', false)
    }
  },
  async addContactTags ({ dispatch }, contactTag) {
    await post('tags/contactTags', contactTag)
  },
  async bulkUpdateTags ({ dispatch }, data) {
    await put('tags/bulkUpdate', data)
    dispatch('fetchTags')
  },
  async bulkDeleteTags ({ dispatch }, data) {
    await post('tags/bulkDelete', data)
    dispatch('fetchTags')
  }
}

const mutations = {
  setTags: (state, tags) => state.tags = tags,
  setFetchingTags: (state, fetching) => state.fetchingTags = fetching,
  setEnabledTags: (state, tags) => state.enabledTags = tags,
  setFetchingEnabledTags: (state, fetching) => state.fetchingEnabledTags = fetching,
  setContactTags: (state, contactTags) => state.contactTags = contactTags,
  setFetchingContactTags: (state, fetching) => state.fetchingContactTags = fetching
}

export default {
  state, getters, actions, mutations
}
